const workshops = {
    FSW2025: {
        name: "2025 FSW Workshop",
        id: "FSW2025",
        public: true,
        live: false,
        open: true,
        latest: true,
        minigame: false,
        host: "at Kane Hall at the University of Washington by Stoke Space",
        location: "Seattle, WA",
        date: Date.parse("March 24, 2025"),
        date_range: "March 24-27, 2025",
        hero: require("@/assets/hosts/stoke.png"),
        media: require("@/assets/hosts/stoke.png"),
        blurb: "18th Annual Flight Software Workshop",
        // subscribe: "https://lp.constantcontactpages.com/su/8w2sKSr/join",
        tickets: "https://www.eventbrite.com/e/2025-flight-software-workshop-registration-1039098079327",
        description: [
            "**Stoke Space** in conjunction with **The Jet Propulsion Laboratory**, **The Johns Hopkins University Applied Physics Laboratory**, **The Aerospace Corporation**, **The Southwest Research Institute** and **NASA Goddard Space Flight Center**, is hosting the 18th Annual Workshop on Spacecraft Flight Software (FSW 2025) in Seattle, Washington from March 24th to 27th, 2025",
            "In the last several years there has been a significant increase in the next generation of flight system architectures for space. Some of the advancements have been in multicore, soft-core, FPGA's, space computers, operating systems, flight software and machine learning. This presents many opportunities in the space industry, as well as some significant challenges.",
            "We are looking forward to another great year of in-depth technical presentations of relevance to all Flight Software engineers. The general themes for FSW-25 are:",
            "- Resiliancy, safety, and security",
            "- Artificial intelligence, machine learning, and onboard data processing",
            "- Space networking",
            "- Onboard data storage and representation",
            "- Flight software architectures, frameworks, and software buses",
            "- Software enabled mission concepts",
            "- Digital twin, config management, and software enabled emulation",
            "- DevOps, continuous integration, and automated testing",
            "- FSW, embedded processor, and FPGA interactions",
            "- Flight Operating Systems",
            "- New languages (bring your non-C work)",
            "- Your hot FSW topic we haven't listed!",
            "Please note: Themes are suggested topics, meant to guide and inspire Workshop participants. Other flight software subjects are welcome for consideration!",
            "We would like to encourage the community to get hands-on at FSW Workshop 2025! While we will continue our presentation tracks, we recommend considering submitting your hardware and software demonstration, as well as your workshop ideas.",
            "<b>If you haven't already done so, <a href='https://lp.constantcontactpages.com/su/8w2sKSr/join' style='color:blue'>subscribe to our e-mail list</a> to get the latest information from our organizing committee.</b>",
        ],
        live_schedule:
            "https://docs.google.com/spreadsheets/d/e/2PACX-1vSQYYBm5VLB1Gg3zX_Shj6p3N2y9zERI18BNarvF3Xc1GsYO4AxG-cPR-r4IIgIPbApJWCo-kUAK747/pubhtml",

        schedule_download:
            "https://docs.google.com/spreadsheets/d/e/2PACX-1vSQYYBm5VLB1Gg3zX_Shj6p3N2y9zERI18BNarvF3Xc1GsYO4AxG-cPR-r4IIgIPbApJWCo-kUAK747/pub?output=pdf",
        static_schedule: require("@/assets/static_docs/2025_FSWW_Schedule.pdf"),
        schedule_info:
            "Below is the 2025 FSW Workshop Schedule. <br/><br/>",
        timeline: [
            {
                name: "Call for Presentations",
                date: "October 14, 2024",
                note: "",
                done: true
            },
            {
                name: "Registration Opens",
                date: "October 14, 2024",
                note: "",
                done: true
            },
            {
                name: "<s>Abstract Submission Deadline</s>",
                date: "November 15, 2024",
                note: "",
                done: false
            },
            {
                name: "Abstract Submission Deadline <i>(Extended!)</i>",
                date: "November 29, 2024",
                note: "",
                done: true
            },
            {
                name: "Abstract Acceptance Notification",
                date: "January 13, 2025",
                note: "",
                done: true
            },
            {
                name: "Presentation Submission Deadline",
                date: "February 24, 2025",
                note: "",
                done: false
            },
            {
                name: "Workshop",
                date: "March 24-27, 2025",
                note: "",
                done: false
            },
        ],
        committee: [
            {
                position: "General Chair",
                name: "Chris Heistand",
                image: ""
            },
            {
                position: "Technical Chair / Co-Chair",
                name: "Luis Rodriguez / Violet Torossian",
                image: ""
            },
            {
                position: "Communication Chair / Co-Chair",
                name: "Diana Saha / Miles Segler",
                image: ""
            },
            {
                position: "Networking Chair / Co-Chair",
                name: "Eddie Benowitz / Andre Choi",
                image: ""
            },
            {
                position: "Sponsor Relations Co-Chairs",
                name: "Brandon Haber / Stephen Escobedo / Robert Klar / Chris Monaco",
                image: ""
            },
            {
                position: "Website Chair / Co-Chair",
                name: "Joseph Gruber / Taleen Sarkissian",
                image: ""
            },
            {
                position: "Organizing Chair",
                name: "Josh Sherbrooke",
                image: ""
            },
        ],
        keynotes: [
            {
                // Monday
                position: "COO",
                name: "Kelly Hennig",
                institution: "Stoke Space",
                image: require("@/assets/keynotes/2025/kelly_hennig_edited.jpg"),
                bio:
                    "Before joining Stoke as COO, Kelly Hennig held both executive and engineering roles at Raytheon, as well as " +
                    "multiple engineering, program management, and strategy roles at Northrop Grumman. Hennig’s expertise includes leading " +
                    "development programs, including proposing, capturing and executing major contracts. She has been awarded 10 patents and " +
                    "5 trade secrets, as well as multiple awards and distinctions for innovation and engineering. She holds a BS in electrical " +
                    "engineering from Rutgers, and an MS in electrical engineering from the University of Michigan. As COO of Stoke, Hennig " +
                    "strategically guides the company as it scales employees, processes, and operations while building the world’s first rapidly " +
                    "reusable rocket."
            },
            {
                // Tuesday
                position: "Flight Software Engineer",
                name: "David Cummings",
                institution: "NASA Jet Propulsion Laboratory",
                image: require("@/assets/keynotes/2025/david_cummings_edited.jpg"),
                bio:
                    "David Cummings has over forty years of experience in computer system design and development and technical management, as well as extensive consulting " +
                    "experience as an expert witness. He was a contractor at JPL from 1987-1996, where his work included designing major portions of the Mars Pathfinder " +
                    "flight software architecture and implementing significant portions of that flight software. The Mars Pathfinder flight software architecture has been " +
                    "used as the basis for the flight software on all of JPL’s Mars rover missions since then, and on other JPL spacecraft as well. After Pathfinder, David " +
                    "spent more than two decades in the commercial world, during which time he held Director/VP positions at various companies. In 2019 he was drawn back to " +
                    "flight software at JPL. Since then, he has worked on the flight software for Mars 2020, Europa Clipper, Psyche, and the Mars Sample Return Lander. In " +
                    "November 2023, after Voyager 1 unexpectedly stopped transmitting data, he was asked to join a tiger team assembled by JPL management to attempt to determine " +
                    "the cause of the failure and provide a fix. That is the subject of his keynote presentation. David received an A.B. in Physics from Harvard University, and an M.S. " +
                    "and Ph.D. in Computer Science from UCLA."
            },
            {
                // Wednesday
                position: "Assistant Group Leader, Control and Autonomous Systems",
                name: "Trevor Ashley",
                institution: "MIT Lincoln Laboratory",
                image: require("@/assets/keynotes/2025/trevor_ashley_edited.jpg"),
                bio:
                    "Trevor Ashley is currently an Assistant Leader of the Control and Autonomous Systems Engineering Group at MIT Lincoln Laboratory. " +
                    "In 2016, Trevor received a PhD in Mechanical Engineering from Boston University where his thesis focused on developing control " +
                    "theoretic methods for tracking fluorescent nanoparticles for biological applications. He has several years of industry experience " +
                    "ranging from capital equipment for the semiconductor industry to autonomous ground vehicles for the defense industry. Trevor joined " +
                    "MIT Lincoln Laboratory as Technical Staff in 2018 where his work primarily focused on developing and testing perception-aware autonomy " +
                    "algorithms for various multi-domain platforms."
            },
            {
                // Thursday
                position: "Chief of Space Policy",
                name: "Casey Dreier",
                institution: "The Planetary Society",
                image: require("@/assets/keynotes/2025/casey_dreier.jpg"),
                bio:
                    "Since 2012, Casey Dreier has led the advocacy and space policy work of The Planetary Society, the world's largest independent, member-supported " +
                    "space nonprofit organization. He educates and empowers the public to improve civic engagement in space science and exploration; works to demystify " +
                    "the process of space policy through writing and teaching; and ensures that the values of curiosity, inquisitiveness, and peaceful exploration are " +
                    "represented within the political system."
            },
        ],
        attendee_info: {
            general: [
                {
                    p:
                        "The workshop will be in person, held in Seattle, WA. Attendance will be limited due to venue capacity. Information " +
                        "regarding how to register and any costs that may be required to cover any added workshop activities will be " +
                        "available when registration opens. Presentations are not necessary to attend the workshop.\n"
                },
                {
                    p:
                        "</br><h1 class='subtitle'><b>Presentations:</b></h1>" +
                        "This is a presentation-only workshop (no papers). Those wishing to present must submit an abstract by the deadline " +
                        "listed above. All abstracts must be ready for public release. Presentations are videotaped and published on our  " +
                        "<a href='https://www.youtube.com/c/FlightSoftwareWorkshop' style='color: blue'>YouTube Channel</a> " +
                        "at a later date. Therefore, presentations **must not** contain US Export Controlled or ITAR information, and " +
                        "notice of this must be indicated on all presentations. All primary authors of the selected presentations **must** " +
                        "submit required release forms which will be provided later. Authors will receive the required release forms that they " +
                        "must review with the legal experts in their organization, sign, and upload prior to the presentation upload deadline. Primary " +
                        "authors will receive a Google Drive URL to upload Abstracts and Presentations after registration. See additional instructions " +
                        "below for special submissions such as demos, workshops, and panels."
                },
                {
                    p:
                        "</br><h1 class='subtitle'><b>Abstract Submissions</b></h1> " +
                        "<li>Submit your abstract, 400 words or less, via e-mail to <a href='mailto:abstracts@flightsoftware.org' style='color: blue'>abstracts@flightsoftware.org</a>. " +
                        "<li>Use the subject line <q>Abstract submission: </q> + your submission title. " +
                        "<li>The deadline for abstract submission has been extended to Friday, November 29, 2024" +
                        "<li>Want to submit a demo, workshop, or panel? See the special instructions below. " +
                        "<li>Authors will receive discounted registration upon abstract acceptance."
                },
                {
                    p:
                        "</br><h1 class='subtitle'><b>Special Instructions for Demos, Workshops, and Panels</b></h1> " +
                        "Demos, workshops, and panels are special sessions intended to bring hands-on flavor to the Workshop.</br> " +
                        "<br>A <b style='color: navy'>presentation</b> is a standard talk limited to 20-25 minutes with audience questions. " +
                        "<br>A <b style='color: green'>demo</b> consists of either a code or hardware walkthrough, followed by a real-time demonstration. " +
                        "<br>A <b style='color: purple'>workshop</b> takes the demo a step further and allows users to work through the problem side-by-side with the presenter. " +
                        "<br>A <b style='color: orange'>panel discussion</b> consists of a group of individuals discussing both pre-selected and live audience questions focused on a specific topic.</br> " +
                        "<br>In addition to general submission instructions, make sure to: " +
                        "<br>1. Add <q>(<b style='color: green'>demo</b>)</q>, <q>(<b style='color: purple'>workshop</b>)</q>, or <q>(<b style='color: orange'>panel</b>)</q> to the end of your e-mail subject line. " +
                        "<br>2. In your submission e- mail, please note the expected duration of your activity. </li> </br>" +
                        "<br>We will evaluate each submission individually and work with the author(s) to understand best fit, timing, and resource needs for the Workshop." +
                        "<br>Note: we discourage submissions with the main purpose of advertisement -- contact us for sponsorship opportunities instead."
                },
                {
                    p:
                        "</br><h1 class='subtitle'><b>Sponsorship Opportunities:</b></h1>" +
                        "The Aerospace Corporation, NASA Jet Propulsion Laboratory, Southwest Research Institute, and The Johns Hopkins University Applied Physics Laboratory " +
                        "are the Founding Sponsors of the Flight Software Workshop. Additional sponsorship opportunities exist for the 2025 Workshop. Please email inquiries " +
                        "to Sponsor Relations at <a href='mailto:sponsor@flightsoftware.org' style='color:blue'>sponsor@flightsoftware.org</a>.",
                },
            ],
            faqs: [
                {
                    Q: "How long are presentations?",
                    A:
                        "Presentations are 25 minutes long. Presenters may choose to include " +
                        "Q&A within this given time."
                },
                {
                    Q: "What is the refund policy?",
                    A: "We do not provide refunds for the FSW Workshop, but tickets may be transferred to someone else for this year's workshop. Please reach out for more information."
                },
                {
                    Q:
                        "What options for interactivity, like Q&A, will be supported?",
                    A:
                        "Questions can be submitted via Slack Workspace or in person. More details will be provided prior to the conference."
                },
                {
                    Q: "When will Talk recordings become available online?",
                    A:
                        "Live Talk recordings this year will not become available immediately after the presentation. We aim to publish all Live Presentation " +
                        "recordings at the end of the Workshop week."
                }
            ]
        },
    },
    FSW2024: {
        name: "2024 FSW Workshop",
        id: "FSW2024",
        public: true,
        live: false,
        open: false,
        latest: false,
        minigame: false,
        host: "at Southwest Research Institute",
        location: "San Antonio, TX",
        date: Date.parse("May 6, 2024"),
        date_range: "May 6-9 2024",
        hero: require("@/assets/hosts/swri.jpg"),
        media: require("@/assets/hosts/swri.jpg"),
        blurb: "17th Annual Flight Software Workshop",
        youtube_playlist: "https://www.youtube.com/playlist?list=PLK-T7jljJ6zZpwRZLT4S5Of8Sfq9mgxIn",
        tickets: "https://www.eventbrite.com/e/2024-flight-software-workshop-registration-790181352697?aff=oddtdtcreator",
        presentations: [],
        pres_slides_link:
            "https://drive.google.com/embeddedfolderview?id=1a_5Oe-Q2C2-ZfFIRguJk03ps829dVuNT",
        description: [
            "**The Southwest Research Institute** in conjunction with **The Jet Propulsion Laboratory**, **The Johns Hopkins University Applied Physics Laboratory**, **The Aerospace Corporation**, and **NASA Goddard Space Flight Center**, is hosting the 17th Annual Workshop on Spacecraft Flight Software (FSW 2024) in San Antonio, Texas from May 6th to 9th 2024",
            "In the last several years there has been a significant increase in the next generation of flight system architectures for space. Some of the advancements have been in multicore, soft-core, FPGA's, space computers, operating systems, flight software and machine learning. This presents many opportunities in the space industry, as well as some significant challenges.",
            "We are looking forward to another great year of in-depth technical presentations of relevance to all Flight Software engineers. The general themes for FSW-24 are:",
            "- Resiliancy, safety, and security",
            "- Artificial intelligence, machine learning, and onboard data processing",
            "- Space networking",
            "- Onboard data storage and representation",
            "- Flight software architectures, frameworks, and software buses",
            "- Software enabled mission concepts",
            "- Digital twin, config management, and software enabled emulation",
            "- DevOps, continuous integration, and automated testing",
            "- FSW, embedded processor, and FPGA interactions",
            "- Flight Operating Systems",
            "- New languages (bring your non-C work)",
            "- Your hot FSW topic we haven't listed!",
            "Please note: Themes are suggested topics, meant to guide and inspire Workshop participants. Other flight software subjects are welcome for consideration!",
            "We would like to encourage the community to get hands-on at FSW Workshop 2024! While we will continue our presentation tracks, we recommend considering submitting your hardware and software demonstration, as well as your workshop ideas.",
            "<b>If you haven't already done so, <a href='https://lp.constantcontactpages.com/su/8w2sKSr/join' style='color:blue'>subscribe to our e-mail list</a> to get the latest information from our organizing committee.</b>",
        ],
        live_schedule:
            "https://docs.google.com/spreadsheets/d/e/2PACX-1vTMiwpArVwtgnY9I2z3_gcmrVyMd3GG5dD5reaJS2H9RhqdgG7on2H3gWnAd7hoDaX_A6pNkGBD3duN/pubhtml",

        schedule_download:
            "https://docs.google.com/spreadsheets/d/e/2PACX-1vTMiwpArVwtgnY9I2z3_gcmrVyMd3GG5dD5reaJS2H9RhqdgG7on2H3gWnAd7hoDaX_A6pNkGBD3duN/pub?output=pdf",
        static_schedule: require("@/assets/static_docs/2024_FSWW_Schedule.pdf"),
        schedule_info:
            "Below is the 2024 FSW Workshop Schedule. <br/><br/>",
        pre_recorded_talks:
            "https://docs.google.com/spreadsheets/d/e/2PACX-1vT8neaunNfk-NraPFuq9GcxX3EqRJX8Dict3xov9F3DZLQGtzKN_-kDMbrEsnG-Cw/pubhtml",
        pre_recorded_talks_height: 600,
        timeline: [
            {
                name: "Call for Presentations",
                date: "December 18, 2023",
                note: "",
                done: true
            },
            {
                name: "Registration Opens",
                date: "January 8, 2024",
                note: "",
                done: true
            },
            {
                name: "<s>Abstract Submission Deadline</s>",
                date: "January 29, 2024",
                note: "",
                done: false
            },
            {
                name: "Abstract Submission Deadline <i>(Extended!)</i>",
                date: "February 12, 2024",
                note: "",
                done: true
            },
            {
                name: "Abstract Acceptance Notification",
                date: "March 4, 2024",
                note: "",
                done: true
            },
            {
                name: "Presentation Submission Deadline",
                date: "April 16, 2024",
                note: "",
                done: true
            },
            {
                name: "Workshop",
                date: "May 6-9, 2024",
                note: "",
                done: true
            },
        ],
        committee: [
            {
                position: "General Chair",
                name: "Stephen Escobedo",
                image: ""
            },
            {
                position: "Technical Chair / Co-Chair",
                name: "Violet Torossian / Robert Klar",
                image: ""
            },
            {
                position: "Communication Chair / Co-Chair",
                name: "Eddie Benowitz / Miles Segler",
                image: ""
            },
            {
                position: "Networking Chair / Co-Chair",
                name: "Andre Choi / Brandon Haber",
                image: ""
            },
            {
                position: "Website Chair",
                name: "Taleen Sarkissian",
                image: ""
            },
            {
                position: "Sponsor Relations Chair",
                name: "Donald Poole",
                image: ""
            },
            {
                position: "Organizing Chair",
                name: "De Anna Melendez",
                image: ""
            },
        ],
        sponsors: [
            require("@/assets/sponsors/Metecs_Logo_Large.png"),
            require("@/assets/sponsors/mathworks.png"),
            require("@/assets/sponsors/gaisler.png"),
            require("@/assets/sponsors/DDC-ILogoHighRes.jpg"),
            require("@/assets/sponsors/Odyssey-Logo-edited.jpg"),
            require("@/assets/sponsors/oar.png"),
        ],
        keynotes: [
            {
                // Monday
                position: "Principal Investigator, PUNCH Mission",
                name: "Dr. Craig DeForest",
                institution: "Southwest Reaseach Institute",
                image: require("@/assets/keynotes/2024/craig_deforest_edited.jpg"),
                bio:
                    "Dr. Craig DeForest directs the Department of Solar and Heliospheric Physics in SwRI’s Division 19, and leads " +
                    "the Polarimeter to Unify the Corona and Heliosphere(PUNCH) mission, a NASA Small Explorer set to launch in 2025, " +
                    "and recently completed a term as Chair of the American Astronomical Society’s Solar Physics Division. He has published " +
                    "over 100 refereed scientific publications and four patents related to solar imaging and data processing.\n\n" +
                    "Dr. DeForest has been studying the Sun and its immediate environment for over three decades, and has participated in " +
                    "nearly every NASA solar mission since the mid 1990s. He received his Ph.D.from Stanford University in 1995, and came " +
                    "to SwRI as a postdoc in 1999. Since then, he has focused on deep image processing and signal separation. Recent work " +
                    "includes imaging the outer reaches of the solar corona and the solar wind itself."
            },
            {
                // Tuesday
                position: "AFRL Space Cyber Resiliency Tech Lead",
                name: "Joseph Trujillo",
                institution: "Air Force Research Labs (AFRL) Space Vehicles Directorate",
                image: require("@/assets/keynotes/2024/joseph_trujillo_edited.jpg"),
                bio:
                    "Mr. Trujillo is the technical lead for the Space Cyber Resiliency program at the Air Force Research Labs Space Vehicles Directorate " +
                    "where he leads a team of space cyber research scientists and engineers whose job is to secure the nations Space assets from cyber attack. " +
                    "He has a B.S. in Aerospace Engineering and has held positions as software/web developer, lead, and architect for companies such as " +
                    "Microsoft, Disney, and other Fortune 500 companies.  He has contributed to shaping policy at the DoD and federal level for Space Cyber. " +
                    "He has contributed to the Secretary of the Air Force (SECAF) ‘Enhancing Operational Cybersecurity’ study as the AFRL Space Cyber representative " +
                    "for the Scientific Advisory Board (SAB). He has contributed designed-in cyber security to the workshop hosted by the Office of National Cyber " +
                    "Director (ONCD) for the Whitehouse."
            },
            {
                // Wednesday
                position: "Chief Engineer, Satellite Servicing",
                name: "Joshua Ross",
                institution: "Northrop Grumman",
                image: require("@/assets/keynotes/2024/joshua_ross_edited.jpg"),
                bio:
                    "Joshua Ross is the chief engineer for Northrop Grumman's Satellite Servicing operating unit. In this role, " +
                    "he is responsible for the technical success of multiple satellite servicing development programs including " +
                    "the Mission Robotics Vehicle (MRV), Mission Extension Vehicle (MEV), and the Mission Extension Pod " +
                    "(MEP). As chief engineer, Mr. Ross regularly collaborates with Defense Advanced Research Projects " +
                    "Agency (DARPA) and the U.S. Naval Research Laboratory (NRL) to develop a robotic servicing vehicle to " +
                    "greatly enhance servicing capabilities for vehicles in the Geosynchronous Earth Orbit belt."
            },
            {
                // Thursday
                position: "Freelance science communicator, space artist, and author",
                name: "Emily Lakdawalla",
                //institution: " ",
                image: require("@/assets/keynotes/2024/emily_ladawalla.jpg"),
                bio:
                    "Emily Lakdawalla is a science writer and space artist. She covers solar system exploration for outlets including Sky & Telescope " +
                    "and BBC Sky at Night and is a frequent radio and TV guest explaining planets and missions. Her first book, The Design and Engineering " +
                    "of Curiosity, was published in 2018."
            },
        ],
        attendee_info: {
            general: [
                {
                    p:
                        "The workshop will be in person, held in San Antonio, TX. Attendance will be limited due to venue capacity. Information " +
                        "regarding how to register and any costs that may be required to cover any added workshop activities will be " +
                        "available when registration opens. Presentations are not necessary to attend the workshop.\n" +
                        "Join us in person and take advantage of exclusive discounted hotel rates at Marriott! " +
                        "Book your accommodation now through the following link: <a href='https://www.marriott.com/events/start.mi?id=1708040194084&key=GRP' style='color: blue'>Marriott Event Booking</a>. " +
                        "Secure your discounted rate today!"

                },
                {
                    p:
                        "</br><h1 class='subtitle'><b>Presentations:</b></h1>" +
                        "This is a presentation-only workshop (no papers). Those wishing to present must submit an abstract by the deadline " +
                        "listed above. All abstracts must be ready for public release. Presentations are videotaped and published on our  " +
                        "<a href='https://www.youtube.com/c/FlightSoftwareWorkshop' style='color: blue'>YouTube Channel</a> " +
                        "at a later date. Therefore, presentations **must not** contain US Export Controlled or ITAR information, and " +
                        "notice of this must be indicated on all presentations. All primary authors of the selected presentations **must** " +
                        "submit required release forms which will be provided later. Authors will receive the required release forms that they " +
                        "must review with the legal experts in their organization, sign, and upload prior to the presentation upload deadline. Primary " +
                        "authors will receive a Google Drive URL to upload Abstracts and Presentations after registration. See additional instructions " +
                        "below for special submissions such as demos, workshops, and panels."
                },
                {
                    p:
                        "</br><h1 class='subtitle'><b>Abstract Submissions</b></h1> " +
                        "<li>Submit your abstract, 400 words or less, via e-mail to <a href='mailto:abstracts@flightsoftware.org' style='color: blue'>abstracts@flightsoftware.org</a>. " +
                        "<li>Use the subject line <q>Abstract submission: </q> + your submission title. " +
                        "<li>The deadline for abstract submission has been extended to Monday, February 12, 2024" +
                        "<li>Want to submit a demo, workshop, or panel? See the special instructions below. " +
                        "<li>Authors will receive discounted registration upon abstract acceptance."
                },
                {
                    p:
                        "</br><h1 class='subtitle'><b>Special Instructions for Demos, Workshops, and Panels</b></h1> " +
                        "Demos, workshops, and panels are special sessions intended to bring hands-on flavor to the Workshop.</br> " +
                        "<br>A <b style='color: navy'>presentation</b> is a standard talk limited to 20-25 minutes with audience questions. " +
                        "<br>A <b style='color: green'>demo</b> consists of either a code or hardware walkthrough, followed by a real-time demonstration. " +
                        "<br>A <b style='color: purple'>workshop</b> takes the demo a step further and allows users to work through the problem side-by-side with the presenter. " +
                        "<br>A <b style='color: orange'>panel discussion</b> consists of a group of individuals discussing both pre-selected and live audience questions focused on a specific topic.</br> " +
                        "<br>In addition to general submission instructions, make sure to: " +
                        "<br>1. Add <q>(<b style='color: green'>demo</b>)</q>, <q>(<b style='color: purple'>workshop</b>)</q>, or <q>(<b style='color: orange'>panel</b>)</q> to the end of your e-mail subject line. " +
                        "<br>2. In your submission e- mail, please note the expected duration of your activity. </li> </br>" +
                        "<br>We will evaluate each submission individually and work with the author(s) to understand best fit, timing, and resource needs for the Workshop." +
                        "<br>Note: we discourage submissions with the main purpose of advertisement -- contact us for sponsorship opportunities instead."
                },
                {
                    p:
                        "</br><h1 class='subtitle'><b>Sponsorship Opportunities:</b></h1>" +
                        "The Aerospace Corporation, NASA Jet Propulsion Laboratory, Southwest Research Institute, and The Johns Hopkins University Applied Physics Laboratory " +
                        "are the Founding Sponsors of the Flight Software Workshop. Additional sponsorship opportunities exist for the 2024 Workshop. Please email inquiries " +
                        "to Donald Poole (Sponsor Relations) at <a href='mailto:sponsor@flightsoftware.org' style='color:blue'>sponsor@flightsoftware.org</a>.",
                },
            ],
            faqs: [
                {
                    Q: "How long are presentations?",
                    A:
                        "Presentations are 25 minutes long. Presenters may choose to include " +
                        "Q&A within this given time."
                },
                {
                    Q: "What is the refund policy?",
                    A: "We do not provide refunds for the FSW Workshop, but tickets may be transferred to someone else for this year's workshop. Please reach out for more information."
                },
                {
                    Q:
                        "What options for interactivity, like Q&A, will be supported?",
                    A:
                        "Questions can be submitted via Slack Workspace or in person. More details will be provided prior to the conference."
                },
                {
                    Q: "When will Talk recordings become available online?",
                    A:
                        "Live Talk recordings this year will not become available immediately after the presentation. We aim to publish all Live Presentation " +
                        "recordings at the end of the Workshop week."
                }
            ]
        }
    },
    FSW2023: {
        name: "2023 FSW Workshop",
        id: "FSW2023",
        public: true,
        live: false,
        open: false,
        latest: false,
        minigame: false,
        // host: "NASA Jet Propulsion Laboratory",
        host: "at Beckman Institute at the California Institute of Technology by JPL",
        location: "Pasadena, CA",
        date: Date.parse("March 20, 2023"),
        date_range: "March 20-23, 2023",
        hero: require("@/assets/hosts/caltech.jpg"),
        media: require("@/assets/hosts/caltech.jpg"),
        blurb: "16th Annual Flight Software Workshop",
        // youtube_playlist:
        //     "https://youtube.com/playlist?list=PLK-T7jljJ6zb0ALxjewsZ3amBTHPXH7qC",
        subscribe: "https://lp.constantcontactpages.com/su/8w2sKSr/join",
        tickets: "https://www.eventbrite.com/e/2023-flight-software-workshop-tickets-417418769777",
        // Presentations portion has correct drive link
        presentations: [],
        pres_slides_link:
            "https://drive.google.com/embeddedfolderview?id=1YMlgtH9YQyP9QcxDKCD7Ktgq1ScClVQ-",
        description: [
            "**The Jet Propulsion Laboratory** in conjunction with **The Johns Hopkins University Applied Physics Laboratory**, **The Aerospace Corporation**, **NASA Goddard Space Flight Center** and **Southwest Research Institute**, is hosting the 16th Annual Workshop on Spacecraft Flight Software (FSW 2023) at the **California Institute of Technology** in March 2023",
            "In the last several years there has been a significant increase in the next generation of flight system architectures for space. Some of the advancements have been in multicore, soft-core, FPGA's, space computers, operating systems, flight software and machine learning. This presents many opportunities in the space industry, as well as some significant challenges.",
            "We are looking forward to another great year of in-depth technical presentations of relevance to all Flight Software engineers. The general themes for FSW-23 are:",
            "- Artificial intelligence, machine learning, and onboard data processing",
            "- Space networking",
            "- Onboard data storage and representation",
            "- Flight software architectures, frameworks, and software buses",
            "- Software enabled mission concepts",
            "- Digital twin, config management, and software enabled emulation",
            "- DevOps, continuous integration, and automated testing",
            "- FSW, embedded processor, and FPGA interactions",
            "- Flight Operating Systems",
            "- New languages (bring your non-C work)",
            "- Your hot FSW topic we haven't listed!",
            "Please note: Themes are suggested topics, meant to guide and inspire Workshop participants. Other flight software subjects are welcome for consideration!",
            "We would like to encourage the community to get hands-on at FSW Workshop 2023! While we will continue our presentation tracks, we recommend considering submitting your hardware and software demonstration, as well as your workshop ideas.",
            "<b>If you haven't already done so, <a href='https://lp.constantcontactpages.com/su/8w2sKSr/join' style='color:blue'>subscribe to our e-mail list</a> to get the latest information from our organizing committee.</b>",
        ],
        live_schedule:
            "https://docs.google.com/spreadsheets/d/e/2PACX-1vSOWzvwnTqHu0jscm7iZSiicUg4p9tWpUxIwGNn9-r0XqYDkquX9bWc6OyFpeOmZbAx5aaCXZEOaW_W/pubhtml",
        schedule_download:
            "https://docs.google.com/spreadsheets/d/e/2PACX-1vSOWzvwnTqHu0jscm7iZSiicUg4p9tWpUxIwGNn9-r0XqYDkquX9bWc6OyFpeOmZbAx5aaCXZEOaW_W/pub?output=pdf",
        static_schedule: require("@/assets/static_docs/2023_FSWW_Schedule.pdf"),
        schedule_info:
            "Below is the 2023 FSW Workshop Schedule. <br/><br/>",
        pre_recorded_talks:
            "https://docs.google.com/spreadsheets/d/e/2PACX-1vSH6JIS_0cmGgDFUH784KBeYk5FYdd-MqewrK_a1EaI67a3gRqlmjsVqvyn-0lK5g/pubhtml",
        pre_recorded_talks_height: 600,
        timeline: [
            {
                name: "Registration Opens",
                date: "September 16, 2022",
                note: "",
                done: true
            },
            {
                name: "First Call for Abstracts",
                date: "October 1st, 2022",
                note: "",
                done: true
            },
            {
                name: "<s>Abstract Submission Deadline</s>",
                date: "December 12, 2022",
                note: "",
                done: false
            },
            {
                name: "Abstract Submission Deadline <i>(Extended!)</i>",
                date: "December 22, 2022",
                note: "",
                done: true
            },
            {
                name: "Abstract Acceptance Notification",
                date: "January 15th, 2023",
                note: "",
                done: true
            },
            {
                name: "Presentation Submission Deadline",
                date: "February 20, 2023",
                note: "",
                done: true
            },
            {
                name: "Workshop",
                date: "March 20-23, 2023",
                note: "",
                done: true
            },
        ],
        committee: [
            {
                position: "General Chair",
                name: "Violet Torossian (JPL)",
                image: ""
            },
            {
                position: "Technical Chair / Co-Chair",
                name: "Benjamin Bornstein / Eddie Benowitz (JPL)",
                image: ""
            },
            {
                position: "Communication Chair",
                name: "Alexandra Holloway (JPL)",
                image: ""
            },
            {
                position: "Event Coordination Chair / Co-Chair",
                name: "Nicholas Bowman / Steven Doran (JPL)",
                image: ""
            },
            {
                position: "Financial Chair / Co-Chair",
                name: "Violet Torossian / Chris Monaco (JPL/APL)",
                image: ""
            },
            {
                position: "Website Chair / Co-Chair",
                name: "Ryan White / Taleen Sarkissian (JPL)",
                image: ""
            },
            {
                position: "Sponsor Relations Chair / Co-Chair",
                name: "Violet Torossian / Brandon Haber (JPL/APL)",
                image: ""
            },
        ],
        sponsors: [
            require("@/assets/sponsors/Metecs_Logo_Large.png"),
            require("@/assets/sponsors/mathworks.png"),
            require("@/assets/sponsors/adacore.jpg"),
            require("@/assets/sponsors/gaisler.png"),
            require("@/assets/sponsors/oar.png"),
            require("@/assets/sponsors/concurrent-real-time2.png"),
            require("@/assets/sponsors/ldra.png"),
            require("@/assets/sponsors/ctme.stacked.cmyk.png"),
        ],
        keynotes: [
            {
                // Monday
                position: "Bren Professor; Senior Director of AI Research",
                name: "Prof. Anima Anandkumar",
                institution: "Caltech; NVIDIA",
                image: require("@/assets/keynotes/2023/anima_anandkumar_edited.jpeg"),
                bio:
                    "Anima Anandkumar is a Bren Professor at Caltech and Director of ML Research at NVIDIA. She was " +
                    "previously a Principal Scientist at Amazon Web Services. She has received several honors such as Alfred. " +
                    "P. Sloan Fellowship, NSF Career Award, Young investigator awards from DoD, and Faculty Fellowships " +
                    "from Microsoft, Google, Facebook, and Adobe. She is part of the World Economic Forum's Expert " +
                    "Network. She is passionate about designing principled AI algorithms and applying them in " +
                    "interdisciplinary applications. Her research focus is on unsupervised AI, optimization, and tensor " +
                    "methods."
            },
            {
                // Tuesday
                position: "Senior Researcher and Faculty Member",
                name: "Dr. Leonidas Kosmidis",
                institution: "Barcelona Supercomputing Center (BSC) & Universitat Politècnica de Catalunya (UPC)",
                image: require("@/assets/keynotes/2023/lkosmidi_edited.jpg"),
                bio:
                    "Dr. Leonidas Kosmidis is a Senior Researcher at the Barcelona Supercomputing " +
                    "Center (BSC) and Faculty Member at the Universitat Politècnica de Catalunya (UPC).\n" +
                    "\n" +
                    "He holds a PhD and MSc in Computer Archicture from Universitat Politècnica de " +
                    "Catalunya, Spain, and a BSc in Computer Science from University of Crete, " +
                    "Greece. He is leading the research on embedded GPUs and accelerators for " +
                    "safety critical systems, both at hardware and system software level within " +
                    "the CAOS (Computer Architecture/Operating Systems) group at BSC.\n" +
                    "\n" +
                    "Dr. Kosmidis is the recipient of the RISC-V Educator of the Year Award in 2019 " +
                    "from the RISC-V Foundation and an Honourable Mention for the EuroSyS Roger " +
                    "Needham PhD Award in 2018, which is awarded to the best PhD thesis in Europe in " +
                    "the area of Systems.\n" +
                    "\n" +
                    "He is the Principal Investigator of several projects funded by the European " +
                    "Space Agency (ESA) such as the GPU4S (GPU for Space) and the Horizon Europe " +
                    "METASAT project funded by the European Commission, as well as projects funded by " +
                    "industry such as the Airbus Defence and Space, which focus on the adoption of " +
                    "GPUs in space and avionics systems, including their certification.\n" +
                    "\n" +
                    "He is also participating in several standardisation efforts regarding GPU " +
                    "programming in safety critical systems within Khronos, in Vulkan SC and SYCL SC, " +
                    "as well as an external expert for the revision of the ECSS standards " +
                    "ECSS-E-ST-20-40 and ECSS-Q-ST-60-02C on ASIC, FPGA and IP Core developments.\n" +
                    "\n" +
                    "Website: https://personals.ac.upc.edu/lkosmidi"
            },
            {
                // Wednesday
                position: "Associate Professor of Sociology",
                name: "Dr. Janet Vertesi",
                institution: "Princeton University",
                image: require("@/assets/keynotes/2022/vertesi_sm.png"),
                bio:
                    "Dubbed “Margaret Mead among the Starfleet” in the Times Literary Supplement, Janet Vertesi is " +
                    "associate professor of sociology at Princeton University, where she specializes in the sociology of " +
                    "science, technology, and organizations. For the past fifteen years she has studied how distributed " +
                    "robotic spacecraft teams work together effectively to produce scientific and technical results, " +
                    "including the Mars Exploration Rovers, Cassini, the Europa Clipper mission, New Horizons, and the " +
                    "Interstellar Probe. She is the author of many peer reviewed journal publications in the fields of " +
                    "human-computer interaction and computer-supported cooperative work, alongside her publications in " +
                    "the social studies of science and technology. A faculty member at Princeton’s Center for Information " +
                    "Technology Policy, and an advisory member at the Data & Society Institute, she has written two scholarly " +
                    "books, Seeing Like a Rover: How robots, teams and images craft knowledge of Mars (Chicago, 2015), and " +
                    "Shaping Science: Organizations, Decisions, and Culture on NASA’s Teams (Chicago, 2020). Vertesi is " +
                    "presently writing a book about the ups and downs of funding cycles at NASA, and a book about her series " +
                    "of unusual, evasive experiments in personal data privacy. You can read more about Professor Vertesi and " +
                    "access her publications at http://janet.vertesi.com"
            },
            {
                // Thursday
                position: "Chief Engineer, Engineering Fellow",
                name: "Rob Manning",
                institution: "Jet Propulsion Laboratory NASA/Caltech",
                // talk_title: "",
                image: require("@/assets/keynotes/2023/rob_manning_edited_2023.jpg"),
                bio:
                    "Rob Manning is Chief Engineer for NASA’s Jet Propulsion Laboratory (JPL) as well as Chief Engineer for JPL’s Engineering " +
                    "and Science Directorate. An Engineering Fellow, he has been designing, testing and operating robotic spacecraft for over 40 " +
                    "years including Galileo to Jupiter, Cassini to Saturn and Magellan to Venus and many Mars missions. \n\n" +
                    "In the early 1990's, Rob became the Mars Pathfinder Chief Engineer where he also led the Entry Descent and Landing (EDL) team. " +
                    "After successfully landing and operating the first airbag lander and rover on another planet, he co-conspired the idea to " +
                    "modify the Pathfinder and Sojourner Rover designs to become the Mars Exploration Rovers (MER), Spirit and Opportunity. On MER " +
                    "he led the rover system engineering team as well as the EDL team. At this time, he co-conceived the idea of skycrane landing " +
                    "that was later used by Mars Science Laboratory (MSL). \n\n" +
                    "After MER he became the Mars Program Chief Engineer where he helped plan and integrate the various Mars missions like Phoenix " +
                    "Lander, Mars Reconnaissance Orbiter, MSL and and beyond. \n\n" +
                    "In 2007, Rob became the Chief Engineer for the MSL Project that successfully landed Curiosity Rover on Mars on August 5, 2012. " +
                    "Rob was responsible for ensuring that the design, the test program and the team, working together, would result in a successful " +
                    "landing and a productive rover. Rob wrote about his experiences in a book called “Mars Rover Curiosity: An Inside Account from " +
                    "Curiosity’s Chief Engineer”. \n\n" +
                    "As a result of his luck at JPL, Rob has received four NASA medals, is in the Aviation Week Magazine Space Laureate Hall of Fame " +
                    "in the Smithsonian Air and Space Museum, has received two honorary PhDs, has a minor planet named after him and is an Associate " +
                    "Fellow of the American Institute of Aeronautics and Astronautics. In 2004, SpaceNews magazine named Rob as one of 100 people who " +
                    "made a difference in civil, commercial and military space since 1989. \n\n" +
                    "Rob is a graduate of Caltech and Whitman College where he studied math, physics, computer science, and control systems. He makes " +
                    "his home in Pasadena, CA with his wife Dominique and their daughter, Caline."
            },
        ],
        attendee_info: {
            general: [
                {
                    p:
                        "The workshop will be in person, held in Pasadena, CA. Attendance will be limited due to venue capacity. Information " +
                        "regarding how to register and any costs that may be required to cover any added workshop activities will be " +
                        "available when registration opens. Presentations are not necessary to attend the workshop."
                },
                // { p: "<br/><b>If you haven't already done so, <a href='https://lp.constantcontactpages.com/su/8w2sKSr/join' style='color:blue'>subscribe to our e-mail list</a> to get the latest information from our organizing committee.</b>" },
                {
                    p:
                        "</br><h1 class='subtitle'><b>Presentations:</b></h1>" +
                        "This is a presentation-only workshop (no papers). Those wishing to present must submit an abstract by the deadline " +
                        "listed above. All abstracts must be ready for public release. Presentations are videotaped and published on our  " +
                        "<a href='https://www.youtube.com/c/FlightSoftwareWorkshop' style='color: blue'>YouTube Channel</a> " +
                        "at a later date. Therefore, presentations **must not** contain US Export Controlled or ITAR information, and " +
                        "notice of this must be indicated on all presentations. All primary authors of the selected presentations **must** " +
                        "submit required release forms which will be provided later. Authors will receive the required release forms that they " +
                        "must review with the legal experts in their organization, sign, and upload prior to the presentation upload deadline. Primary " +
                        "authors will receive a Google Drive URL to upload Abstracts and Presentations after registration. See additional instructions " +
                        "below for special submissions such as demos, workshops, and panels."
                },
                {
                    p:
                        "</br><h1 class='subtitle'><b>Abstract Submissions</b></h1> " +
                        "<li>Submit your abstract, 400 words or less, via e-mail to <a href='mailto:abstracts@flightsoftware.org' style='color: blue'>abstracts@flightsoftware.org</a>. " +
                        "<li>Use the subject line <q>Abstract submission: </q> + your submission title. " +
                        "<li>Deadline has been extended to Thursday, December 22, 2022 (23: 59 UTC - 12). " +
                        "<li>Want to submit a demo, workshop, or panel? See the special instructions below. " +
                        "<li>Authors will receive discounted registration upon abstract acceptance."
                },
                {
                    p:
                        "</br><h1 class='subtitle'><b>Special Instructions for Demos, Workshops, and Panels</b></h1> " +
                        "Demos, workshops, and panels are special sessions intended to bring hands-on flavor to the Workshop.</br> " +
                        "<br>A <b style='color: navy'>presentation</b> is a standard talk limited to 20-25 minutes with audience questions. " +
                        "<br>A <b style='color: green'>demo</b> consists of either a code or hardware walkthrough, followed by a real-time demonstration. " +
                        "<br>A <b style='color: purple'>workshop</b> takes the demo a step further and allows users to work through the problem side-by-side with the presenter. " +
                        "<br>A <b style='color: orange'>panel discussion</b> consists of a group of individuals discussing both pre-selected and live audience questions focused on a specific topic.</br> " +
                        "<br>In addition to general submission instructions, make sure to: " +
                        "<br>1. Add <q>(<b style='color: green'>demo</b>)</q>, <q>(<b style='color: purple'>workshop</b>)</q>, or <q>(<b style='color: orange'>panel</b>)</q> to the end of your e-mail subject line. " +
                        "<br>2. In your submission e- mail, please note the expected duration of your activity. </li> </br>" +
                        "<br>We will evaluate each submission individually and work with the author(s) to understand best fit, timing, and resource needs for the Workshop." +
                        "<br>Note: we discourage submissions with the main purpose of advertisement -- contact us for sponsorship opportunities instead."
                },
                {
                    p:
                        "</br><h1 class='subtitle'><b>Sponsorship Opportunities:</b></h1>" +
                        "The Aerospace Corporation, NASA Jet Propulsion Laboratory, Southwest Research Institute, and The Johns Hopkins University Applied Physics Laboratory " +
                        "are the Founding Sponsors of the Flight Software Workshop. Additional sponsorship opportunities exist for the 2023 Workshop. Please email inquiries " +
                        "to Violet Torossian (Sponsor Relations) at <a href='mailto:sponsor@flightsoftware.org' style='color:blue'>sponsor@flightsoftware.org</a>.",
                },
            ],
            faqs: [
                {
                    Q: "How long are presentations?",
                    A:
                        "Presentations are 25 minutes long. Presenters may choose to include " +
                        "Q&A within this given time."
                },
                {
                    Q: "What time does the Workshop start/end?",
                    A:
                        "The Workshop will run from approximately 8:30 AM to approximately 5:00 PM Pacific Time, Monday through Thursday. " +
                        "We chose these times to provide a good time window for all our attendees across the U.S. and internationally. " +
                        "For any of those unable to attend due to conflict or time zone, all talks will be published to the " +
                        "FSW Workshop YouTube after the conference. We appreciate our international attendees and presenters for supporting the Workshop as they " +
                        "are able!"
                },
                {
                    Q: "What is the refund policy?",
                    A: "We do not provide refunds for the FSW Workshop, but tickets may be transferred to someone else for this year's workshop. Please reach out for more information."
                },
                {
                    Q:
                        "What options for interactivity, like Q&A, will be supported?",
                    A:
                        "Questions can be submitted via Slack Workspace or in person. More details will be provided prior to the conference."
                },
                {
                    Q: "When will Talk recordings become available online?",
                    A:
                        "Live Talk recordings this year will not become available immediately after the presentation. We aim to publish all Live Presentation " +
                        "recordings at the end of the Workshop week."
                }
            ]
        },
        code_of_conduct: [
            {
                p:
                    "In order to ensure we conduct a safe and fruitful workshop for all presenters and attendees **we would like all our attendees and presenters to read and respect the Flight Software Workshop Code of Conduct below.** This code of conduct also holds for both Zoom interactions & interactions via Slack."
            },
            {
                link: require("@/assets/static_docs/FSWW23_Code_of_Conduct.pdf")
            }
        ]
    },
    FSW2022: {
        name: "2022 FSW Workshop",
        id: "FSW2022",
        public: true,
        live: false,
        open: false,
        latest: false,
        minigame: false,
        host: "Virtually by JHUAPL",
        // location: "<a style='font-size:25px; color:blue' href='https://www.google.com/url?q=https://jhuapl.zoomgov.com/j/1607549558&sa=D&source=editors&ust=1613003224434000&usg=AFQjCNHqsMiDIzD25HZyEHlo47_8xMRPXA'>Thursday Zoom Link</a>",
        location: "Virtual",
        date: Date.parse("February 7, 2022"),
        date_range: "February 7-10, 2022",
        hero: require("@/assets/hosts/virtual-workshop-d.png"),
        media: require("@/assets/hosts/virtual-workshop-d.png"),
        blurb: "15th Annual Flight Software Workshop",
        tickets: "https://flightsoftware-2022-tickets.eventbrite.com/",
        subscribe: "https://lp.constantcontactpages.com/su/8w2sKSr/join",
        // slack: "",
        forms: true,
        free: true,
        sel_abstracts: false,
        // sel_abstracts_link: "",
        presentations: [],
        pres_slides_link:
            "https://drive.google.com/embeddedfolderview?id=1E2dwBTxZSYxX-LmAPok37lGR9tgT9bWI",
        sponsors: [
            require("@/assets/sponsors/oar.png"),
            require("@/assets/sponsors/adacore.jpg"),
            require("@/assets/sponsors/concurrent-real-time2.png"),
            require("@/assets/sponsors/caes-logo-blk.png"),
            require("@/assets/sponsors/mathworks.png"),
            require("@/assets/sponsors/Metecs_Logo_Large.png")
        ],
        description: [
            "**The Johns Hopkins University Applied Physics Laboratory** in conjunction with **The NASA Jet Propulsion Laboratory**, **The Aerospace Corporation**, **NASA Goddard Space Flight Center** and **Southwest Research Institute**, is hosting the 15th Annual Workshop on Spacecraft Flight Software (FSW 2022) virtually in February 2022.",
            "In the last several years there has been a significant increase in the next generation of flight system architectures for space. Some of the advancements have been in multicore, soft-core, FPGA's, space computers, operating systems, flight software and machine learning. This presents many opportunities in the space industry, as well as some significant challenges.",
            "We are looking forward to another great year of in-depth technical presentations of relevance to all Flight Software engineers. The chosen topics for FSW-22 are:",
            "- Artificial intelligence, machine learning, and onboard data processing",
            "- Space networking",
            "- Onboard data storage and representation",
            "- Flight software architectures, frameworks, and software buses",
            "- Software enabled mission concepts",
            "- Digital twin, config management, and software enabled emulation",
            "- DevOps, continuous integration, and automated testing",
            "- FSW, embedded processor, and FPGA interactions",
            "- Flight Operating Systems",
            "- New languages (bring your non-C work)",
            "- _**New!**_ Student, internship, and university projects",
            // "**Please note**: Themes are suggested topics, meant to guide and inspire Workshop participants. Other flight software subjects are welcome for consideration!",
            // "We would like to encourage the community to get hands-on at FSW Workshop 2022! While we will continue our presentation tracks like previous years, we are opening up an opportunity for (virtual) hardware and software demonstrations, as well as workshop ideas. For example, at FSW Workshop 2019, we had a 2-hour, interactive session where attendees could learn how to set up cFE on a Raspberry Pi.",
            "As a result of not needing a venue and not having other costs associated with an in-person workshop, the `2022 Flight Software Workshop will be FREE for attendees and presenters.`",
            "<b>If you haven't already done so, <a href='https://lp.constantcontactpages.com/su/8w2sKSr/join' style='color:blue'>subscribe to our e-mail list</a> to get the latest information from our organizing committee.</b>"
        ],
        live_schedule:
            "https://docs.google.com/spreadsheets/d/e/2PACX-1vQqwduo4e3xloCboIro9GZeYVMVA10Lb7xjPm8NVlJDCkH8vDtQ9sDSaA5fHuvA5e_4Eh7VNV5-IJpe/pubhtml",
        schedule_download:
            "https://docs.google.com/spreadsheets/d/e/2PACX-1vQqwduo4e3xloCboIro9GZeYVMVA10Lb7xjPm8NVlJDCkH8vDtQ9sDSaA5fHuvA5e_4Eh7VNV5-IJpe/pub?output=pdf",
        static_schedule: require("@/assets/static_docs/2022_FSWW_Schedule.pdf"),
        schedule_info:
            "Below is the 2022 FSW Workshop Schedule. <br/><br/> \
            The daily Webinar Link will be found at the top right corner of each day<br/>\
            <b>The password for all Zoom Webinars and Meetings are sent out daily to attendees and presenters, please check your emails daily for links and passwords.</b><br/> \
            If you can't find the daily e-mail from our team `check your spam folder`.<br/> For any questions feel free to reach out to us at: <a style='color:blue' href='mailto:info@flightsoftware.org'>info@flightsoftware.org</a><br/><br/>",
        pre_recorded_talks:
            "https://docs.google.com/spreadsheets/d/e/2PACX-1vSqw3JApVBbuRBtY4dGrIFFspZgByoGreb_F-4uZY79mweixR0gVRpueKRaXYEk-bq0tUX5K8Sd4Qs0/pubhtml",
        attendee_info: {
            general: [
                {
                    p:
                        "The 2022 workshop will be held virtually to keep our staff and attendees safe during the COVID-19 pandemic. \
                This year, we have decided to make admissions to the 2022 FSW Workshop `FREE`, all that is necessary is an RSVP. \
                <b>If you haven't already done so, <a href='https://lp.constantcontactpages.com/su/8w2sKSr/join' style='color:blue'>subscribe to our e-mail list</a> to get the latest information from our organizing committee.</b>\
                <br/><br/> \
                We have surpassed 700 attendees for FSW-2022, continuing to set the record for the most attendees in FSW Workshop history! \
                Thanks to this influx, we have abstract submissions from over 20 unique organizations, covering a wide array of matters and \
                talented minds. \
                <br/><br/> \
                Our 2022 FSW Workshop Virtual event will be hosted via a combination of Zoom Webinar and Zoom Meetings. All presentations will be held\
                via Zoom Webinar, whereas kiosks will be held in Zoom Meetings. \
                **During presentations** only presenters will be able to share audio \
                and video. Attendees can use the Q&A to ask questions and interact with the presenters.\
                **During kiosks** sponsors will host the Zoom meeting room and attendees will have full interactivity. </br></br>"
                },
                {
                    table:
                        " </br>The table below shows the difference between the two and relates `FSW Workshop Roles` to **Zoom Roles**:\
                <br/><br/> \
                <table class='table is-hoverable is-bordered'> \
                    <thead> \
                    <tr> \
                        <th>Feature</th>\
                        <th>Webinar </br> (All Presentations)</th>\
                        <th>Meeting </br> (Sponsor Kiosks)</th>\
                    </tr>\
                    </thead>\
                    <tbody>\
                        <tr>\
                            <th>Participant Roles</th>\
                            <td> \
                                <ul class='list-item' style='list-style-type:square'> \
                                    <li>All `Presenters` will be **Panelists**. Only **Panelists** will be able to share video and audio during the Presentation</li>\
                                    <li>All `Attendees` will be Zoom **Attendees**. **Attendees** will ask questions via the Q&A section and may interact via Chat</li>\
                                    <li>All `FSW Workshop Reps` and `Technicians` will be **Hosts**. **Hosts** will act as moderators</li>\
                                </ul>\
                            </td>\
                            <td>\
                                <ul class='list-item' style='list-style-type:square'> \
                                    <li>**Hosts** will be `Sponsors` and FSW Workshop `Representatives`</li>\
                                    <li>**Participants** will be all `Attendees` in the kiosk</li>\
                                </ul>\
                            </td>\
                        </tr>\
                        <tr>\
                            <th>Audio/Video Control</th>\
                            <td>\
                                <ul class='list-item' style='list-style-type:square'> \
                                    <li>Only **Panelists** can mute/unmute their own audio</li>\
                                    <li>**Attendees** join in listen-only mode</li>\
                                    <li>During the Thursday Town Hall Session **Hosts** may unmute an attendee to partake in the live conversation</li>\
                                    <li>Video & Screen Sharing will only be available to **Panelists**</li>\
                                </ul>\
                            </td>\
                            <td>\
                                <ul class='list-item' style='list-style-type:square'> \
                                    <li>All **Participants** can mute/unmute their own audio</li>\
                                    <li>**Hosts** can mute/request to unmute **participants**</li>\
                                    <li>Video & Screen Sharing will be available to all **Participants**</li>\
                                </ul>\
                            </td>\
                        </tr>\
                        <tr>\
                            <th>Participant List</th>\
                            <td> \
                            <ul class='list-item' style='list-style-type:square'> \
                                <li>Visible to **Hosts** and **Panelists**</li>\
                            </td>\
                            <td> \
                            <ul class='list-item' style='list-style-type:square'> \
                                <li>Visible to all **Participants**</li> \
                            </td>\
                        </tr>\
                        <tr>\
                            <th>Networking</th>\
                            <td> \
                                <ul class='list-item' style='list-style-type:square'> \
                                    <li>Everyone  may use the **Webinar Chat**</li>\
                                    <li>**Attendees** may ask questions via the Q&A section</li> \
                                    <li>**Panelists** may answer Q&A section questions LIVE or via chat after their presentation</li>\
                                </ul> \
                            </td>\
                            <td>\
                                <ul class='list-item' style='list-style-type:square'> \
                                <li>Everyone may use the **In-meeting Chat**</li>\
                                </ul> \
                            </td>\
                        </tr>\
                    </tbody>\
                </table>"
                },
                {
                    p:
                        "</br><h1 class='subtitle'><b>General Instructions:</b></h1>\
                During the 2022 FSW Workshop, attendees and presenters should check their registered e-mails in the morning for a FSW Workshop e-mail with the password to the Zoom Webinar and Kiosks of the day. </br></br>\
                For general communication between all attendees, keynotes and presentors we will be using <a href='https://slack.com/help/articles/115004071768-What-is-Slack-' style='color:blue'>Slack</a>.\
                Slack is a channel-based platform that allows for all members to communicate in an organized fasion. We will be using channels to divide conversations into topics. The platform allows for \
                direct messaging amongst users, and for public discussions in public channels. We encourage all our attendees, presenters and keynotes to read the 2022 FSW Workshop Code of Conduct before\
                Access to the 2022 FSW Workshop Slack Workspace will be granted to all attendees via e-mail on February 7th, 2022.\
            "
                }
            ],
            faqs: [
                {
                    Q: "When will Talk recordings become available online?",
                    A:
                        "Live Talk recordings this year will not become available immidiately after the presentation. Using Zoom Webinar does not allow us to directly publish to YouTube. However \
                    we aim to publish all Live Presentation recordings by June 1st, 2022. This provides this year's FSW Workshop Committee with sufficient time to edit the recordings \
                    and post each talk as an individual video to our YouTube channel."
                },
                {
                    Q: "What virtual platform will the FSW Workshop be using?",
                    A:
                        "The Workshop Committee has chosen Zoom Webinar for this year's virtual Workshop. The Webinar platform manages presenters and attendees \
                    such that only presenters may be unmuted, and includes a Q&A system with upvotes. Webinar is accessible via web browser, as well as Windows, \
                    Linux, and Mac clients."
                },
                {
                    Q: "How long are presentations?",
                    A:
                        "Presentations are 25 minutes long. Presenters may choose to include \
                    Q&A within this given time."
                },
                {
                    Q:
                        "What options for interactivity, like Q&A, will be supported?",
                    A:
                        "As mentioned, there is the Zoom Webinar Q&A system for presenters to interact with attendees. Questions can be submitted by any \
                    attendee via a text platform and presenters may respond live or by written response. Questions can be upvoted by all attendees to reflect \
                    audience interest.\
                    We are also planning to provide an optional Slack Workspace for further interactivity and networking opportunities. More details will be \
                    provided prior to the conference."
                },
                {
                    Q: "What time does the Workshop start/end?",
                    A:
                        "The Workshop will run from approximately 11:00 AM to approximately 6:00 PM Eastern Time, Monday, Wednesday and Thursday. To accomodate more talks\
                        Tuesday will run to 7:00 PM. We chose these times to provide a good time window for all our attendees across the U.S and internationally. \
                    For any of those unable to attend due to conflict or time zone, all talks will be published to the \
                    FSW Workshop YouTube after the conference. We appreciate our international attendees and presenters for supporting the Workshop as they \
                    are able!"
                },
                {
                    Q: "How do I access the Webinar?",
                    A:
                        "The Webinar link will be available on the FSW Workshop website, \
                    as well as emailed to all attendees. The Webinar will be secured via password, \
                    which will also be included in the email to all attendees. The link will open up \
                    your desktop Zoom application if available, and a browser if not."
                },
                {
                    Q:
                        "How do I interact with the Workshop sponsors in a virtual conference?",
                    A:
                        "Sponsor Kiosks - Each sponsor is hosting an individual sponsor kiosk through Zoom, \
                    on Tuesday and Thursday during a dedicated 45 minutes of the Workshop agenda. Each sponsor \
                    will also have the opportunity to share a summary of their kiosk with the Workshop beforehand.\
                    Sponsor Presentations - Each sponsor is giving a 25-minute presentation during the Workshop \
                    to share their work with the community."
                }
            ]
        },
        code_of_conduct: [
            {
                p:
                    "In order to ensure we conduct a safe and fruitful workshop for all presenters and attendees **we would like all our attendees and presenters to read and respect the Flight Software Workshop Code of Conduct below.** This code of conduct holds for both Zoom interactions & interactions via Slack."
            },
            {
                link: require("@/assets/static_docs/FSW_Workshop_Code_of_Conduct.pdf")
            }
        ],
        timeline: [
            {
                name: "Registration Opens",
                date: "2021-08-16",
                note: "",
                done: true
            },
            {
                name: "First Call for Abstracts",
                date: "2021-09-01",
                note: "",
                done: true
            },
            {
                name: "Abstract Submission Deadline",
                date: "2021-12-03",
                note: "Extended Deadline",
                done: true
            },
            {
                name: "Abstract Acceptance Notification",
                date: "2021-12-10",
                note: "",
                done: true
            },
            {
                name: "Presentation Submission Deadline",
                date: "2022-01-14",
                note: "",
                done: true
            },
            {
                name: "Workshop",
                date: "2022-02-07 -- 2022-02-10",
                note: "",
                done: true
            }
        ],
        committee: [
            {
                position: "General Chair",
                name: "Brandon T. Haber",
                institution: "The JHU Applied Physics Laboratory",
                image: ""
            },
            {
                position: "Communications Chair",
                name: "Campbell A. Ong",
                institution: "The JHU Applied Physics Laboratory",
                image: ""
            },
            {
                position: "Sponsor Relations",
                name: "Amanda K. Voegtlin",
                institution: "The JHU Applied Physics Laboratory",
                image: ""
            },
            {
                position: "Technical Chair",
                name: "Chris Heistand",
                institution: "STOKE Space",
                image: ""
            },
            {
                position: "Financial Chair",
                name: "Chris Monaco",
                institution: "The JHU Applied Physics Laboratory",
                image: ""
            },
            {
                position: "Event Coordinator Chair",
                name: "Luis M. Rodriguez",
                institution: "The JHU Applied Physics Laboratory",
                image: ""
            },
            {
                position: "Virtual Hosting Chair",
                name: "David J. Edell",
                institution: "The JHU Applied Physics Laboratory",
                image: ""
            },
            {
                position: "Website Maintainers",
                name: "Joseph Gruber (Maxar) & Luis Rodriguez (APL)",
                institution: "",
                image: ""
            }
        ],
        keynotes: [
            {
                position: "Aerospace Advisor",
                name: "Hans Koenigsmann",
                institution: "",
                talk_title:
                    "'Can we fix it in software?' - software development in the modern world",
                image: require("@/assets/keynotes/2022/Headshot_Hans_Koenigsmann2022.jpeg"),
                bio:
                    "Dr. Hans Koenigsmann joined the SpaceX team as the 4th technical member in 2002 " +
                    "and developed the avionics, guidance and control, and software departments as Vice " +
                    "President of Avionics. He designed the avionics system of Falcon 1 and supported the " +
                    "early launches as Launch Chief Engineer. He moved into this operational role for Falcon " +
                    "9 and Dragon, and became the Vice President of Flight Reliability (formerly mission assurance). " +
                    "Being responsible to flight risk and safety, he and his team also resolved critical anomalies. " +
                    "With the addition of build reliability he was also responsible for production quality. " +
                    "His responsibilities also included change engineering, process development and the quality " +
                    "system. " +
                    "\n\n " +
                    "He and his team supported more than 100 launches with thorough reviews prior, and flight " +
                    "data reviews post flight. Feeding back the lessons learned, preventing mission failures and " +
                    "improving both production and operation is the top priority for him. " +
                    "\n\n" +
                    "Hans retired from SpaceX in late 2021 after more than 19 years. " +
                    "\n\n" +
                    "He holds a Masters from the Technical University of Berlin and a PhD from the University of " +
                    "Bremen in Germany."
            },
            {
                position: "Author and Strategic Communicator",
                name: "David Hitt",
                institution:
                    "Co-Author, “Homesteading Space: The Skylab Story”",
                talk_title:
                    "(Almost) Everything I Need to Know About Going to Mars I Learned from Skylab",
                image: require("@/assets/keynotes/2022/david_hitt_2022.png"),
                bio: "David Hitt is co-author of two books, “Homesteading Space: The Skylab Story,” " +
                    "written with astronauts Owen Garriott and Joe Kerwin, and “Bold They Rise: The Space " +
                    "Shuttle Early Years,” both part of the University of Nebraska Press' Ordway-Award-winning " +
                    "Outward Odyssey spaceflight history series. He has appeared in the award-winning " +
                    "documentaries “Searching for Skylab” and “Saving Skylab.” Hitt has worked as a strategic " +
                    "communications contractor at NASA’s Marshall Space Flight Center since 2002. Currently employed with MTS " +
                    "Inc, Hitt is a recipient of NASA's Silver Snoopy Award, presented by the agency's Astronaut Office. A former " +
                    "newspaper editor, Hitt is a native of Huntsville, Alabama. He holds a bachelors degree in " +
                    "journalism from the University of Mississippi and is currently pursuing a masters in systems " +
                    "engineering at Embry-Riddle Aeronautical University.  He is a past Policy Formulation  chair for " +
                    "the National Space Society, volunteers at the U.S. Space & Rocket Center in Huntsville and is a " +
                    "senior member of the AIAA."
            },
            {
                position: "Associate Professor of Sociology",
                name: "Janet Vertesi",
                institution: "Princeton University",
                talk_title:
                    "Seeing like a Rover: How Human Teams Matter in Spaceflight Software",
                image: require("@/assets/keynotes/2022/vertesi_sm.png"),
                bio:
                    "Dubbed “Margaret Mead among the Starfleet” in the Times Literary Supplement, Janet Vertesi is " +
                    "associate professor of sociology at Princeton University, where she specializes in the sociology of " +
                    "science, technology, and organizations. For the past fifteen years she has studied how distributed " +
                    "robotic spacecraft teams work together effectively to produce scientific and technical results, " +
                    "including the Mars Exploration Rovers, Cassini, the Europa Clipper mission, New Horizons, and the " +
                    "Interstellar Probe. She is the author of many peer reviewed journal publications in the fields of " +
                    "human-computer interaction and computer-supported cooperative work, alongside her publications in " +
                    "the social studies of science and technology. A faculty member at Princeton’s Center for Information " +
                    "Technology Policy, and an advisory member at the Data & Society Institute, she has written two scholarly " +
                    "books, Seeing Like a Rover: How robots, teams and images craft knowledge of Mars (Chicago, 2015), and " +
                    "Shaping Science: Organizations, Decisions, and Culture on NASA’s Teams (Chicago, 2020). Vertesi is " +
                    "presently writing a book about the ups and downs of funding cycles at NASA, and a book about her series " +
                    "of unusual, evasive experiments in personal data privacy. You can read more about Professor Vertesi and " +
                    "access her publications at http://janet.vertesi.com"
            },
            {
                position: "Managing Executive, Space Exploration Sector",
                name: "Andy Driesman",
                institution:
                    "Johns Hopkins University – Applied Physics Laboratory",
                talk_title:
                    "Management Considerations When People are the Most Valuable Resource",
                image: require("@/assets/keynotes/2022/andy_driesman_2022.png"),
                bio:
                    "Mr. Andrew S. Driesman is Managing Executive of the Space Exploration Sector (SES) within the " +
                    "Johns Hopkins University - Applied Physics Laboratory (APL). Mr. Driesman has extensive experience " +
                    "in program and organizational management as well as system engineering of complex space systems.  " +
                    "He oversees the technical and science staff within the SES and is responsible for strategic efforts " +
                    "within the organization to assure alignment with the vision and strategic plans of the Sector’s two " +
                    "mission areas (Civil Space and National Security Space) and APL.  As the nation’s largest University " +
                    "Affiliated Research Center, APL performs research and development on behalf of the Department of Defense, " +
                    "the intelligence community, the National Aeronautics and Space Administration, and other federal agencies. " +
                    "The Laboratory has more than 8,000 staff members who make critical contributions to a wide variety of " +
                    "nationally and globally significant technical and scientific challenges." +
                    "\n\n" +
                    "Prior to appointment as Managing Executive, Mr. Driesman served as the Program Manager for the Interstellar " +
                    "Mapping and Acceleration Probe and Flight System Manager for Dragonfly, NASA’s mission to explore the " +
                    "surface of Titan. Mr. Driesman led the highly successful Parker Solar Probe program, a mission to explore " +
                    "the Sun’s atmosphere. Responsible to NASA for technical, cost and schedule performance, he successfully " +
                    "managed the multi-organizational mission from conceptual design into operations. Other relevant assignments " +
                    "include Technical Director of the Joint Polar Satellite System at NOAA, a multi-billion dollar system that " +
                    "provides remote sensing data to feed the nation’s weather forecasting models and manager of the system " +
                    "engineering organization within the Space Exploration Sector." +
                    "\n\n" +
                    "Nationally recognized honors include: AIAA Von Braun Award for Space Program Management, NASA Outstanding " +
                    "Public Leadership Medal, AAS Neil Armstrong Flight Achievement Award and the NASA Exceptional Public Service Medal." +
                    "\n\n" +
                    "Mr. Driesman earned a Bachelor of Science degree in electrical engineering and geology from Tufts University and a " +
                    "Master of Science in technical management from the Johns Hopkins University."
            },
            {
                position: "Principal Professional Staff",
                name: "Christopher Krupiarz",
                institution:
                    "Johns Hopkins University – Applied Physics Laboratory",
                talk_title: "Flight Software at JHU/APL",
                image: require("@/assets/keynotes/2022/Krupiarz_Christopher_edited.png"),
                bio:
                    "Mr. Krupiarz is a member of the Johns Hopkins University Applied Physics Laboratory (JHU/APL) Principal Professional " +
                    "staff and the Autonomy lead for the Interstellar Mapping and Acceleration Probe (IMAP) mission. Mr. Krupiarz has over " +
                    "20 years of experience in spacecraft software development and management. He developed flight software for MESSENGER, " +
                    "the Van Allen Belt Storm Probes, New Horizons, and the Parker Solar Probe where he also flight software lead. " +
                    "In addition, Mr. Krupiarz was the JHU/APL Principal Investigator for exploring the use of Delay Tolerant Networking " +
                    "for space communication and served in several line management roles within the JHU/APL Embedded Applications Group."
            }
        ],
        townhalls: [
            {
                name: "Containers",
                moderator: {
                    name: "Chris Heistand",
                    image: require("@/assets/photos/christopher-heistand.png"),
                    roles: [
                        {
                            title: "Technical Chair",
                            institution: "Flight Software Workshop 2022"
                        },
                        {
                            title: "Flight Software Architect",
                            institution: "STOKE Space Technologies"
                        }
                    ]
                },
                panelists: [
                    {
                        name: "Luis Rodriguez",
                        image: require("@/assets/photos/luis-rodriguez.png"),
                        roles: [
                            {
                                title:
                                    "Lead of Avionics Integrated Development Environment",
                                institution: "JHU/APL"
                            }
                        ]
                    },
                    {
                        name: "Liz Rice",
                        image: require("@/assets/photos/liz-rice.png"),
                        roles: [
                            {
                                title: "Chief Open Source Officer",
                                institution: "Isovalent"
                            },
                            {
                                title: "Chair of Technical Oversight Committee",
                                institution: "CNCF"
                            }
                        ]
                    },
                    {
                        name: "Aaron Black",
                        image: require("@/assets/photos/aaron-black.png"),
                        roles: [
                            {
                                title: "CAE Software Environment Deputy Lead",
                                institution: "NASA Jet Propulsion Laboratory"
                            }
                        ]
                    },
                    {
                        name: "Alan Cudmore",
                        image: require("@/assets/photos/alan-cudmore.png"),
                        roles: [
                            {
                                title:
                                    "Computer Engineer, cFS Platform Architect",
                                institution:
                                    "NASA Goddard Space Flight Center, Flight Software Systems Branch"
                            }
                        ]
                    }
                ]
            },
            {
                name: "Frameworks",
                moderator: {
                    name: "Chris Heistand",
                    image: require("@/assets/photos/christopher-heistand.png"),
                    roles: [
                        {
                            title: "Technical Chair",
                            institution: "Flight Software Workshop 2022"
                        },
                        {
                            title: "Flight Software Architect",
                            institution: "STOKE Space Technologies"
                        }
                    ]
                },
                panelists: [
                    {
                        name: "Timothy K. Canham",
                        image: require("@/assets/photos/timothy-canham.png"),
                        roles: [
                            {
                                title:
                                    "F Prime FSW framework architect<br />Ingenuity Mars Helicopter FSW and operations lead",
                                institution: "NASA Jet Propulsion Laboratory"
                            }
                        ]
                    },
                    {
                        name: "Amalaye Oyake",
                        image: require("@/assets/photos/amalaye-oyake.png"),
                        roles: [
                            {
                                title: "Flight Software Lead, Space ROS",
                                institution: "Blue Origin"
                            }
                        ]
                    },
                    {
                        name: "Peter Mendham",
                        image: require("@/assets/photos/peter-mendham.png"),
                        roles: [
                            {
                                title: "CEO and Founder",
                                institution: "Bright Ascension Ltd"
                            }
                        ]
                    },
                    {
                        name: "Kevin Gordon",
                        image: require("@/assets/photos/kevin-gordon.png"),
                        roles: [
                            {
                                title:
                                    "Director, Aerospace Software Engineering",
                                institution: "Firefly Aerospace"
                            }
                        ]
                    },
                    {
                        name: "Christophe Honvault",
                        image: require("@/assets/photos/placeholder.png"),
                        roles: [
                            {
                                title:
                                    "Head of the Software Technology section",
                                institution: "European Space Agency"
                            }
                        ]
                    },

                    {
                        name: "Jacob Hageman",
                        image: require("@/assets/photos/jacob-hageman.png"),
                        roles: [
                            {
                                title:
                                    "Former cFS Framework Product Development Lead<br />Current Senior Flight Software Engineer",
                                institution:
                                    "NASA Goddard Space Flight Center, Software Engineering Division"
                            }
                        ]
                    }
                ]
            }
        ]
    },
    FSW2021: {
        name: "Virtual 2021 FSW Workshop",
        id: "FSW2021",
        public: true,
        live: false,
        open: false,
        latest: false,
        minigame: false,
        host: "Virtually by The Applied Physics Laboratory",
        // location: "<a style='font-size:25px; color:blue' href='https://www.google.com/url?q=https://jhuapl.zoomgov.com/j/1607549558&sa=D&source=editors&ust=1613003224434000&usg=AFQjCNHqsMiDIzD25HZyEHlo47_8xMRPXA'>Thursday Zoom Link</a>",
        location: "Held Virtually on Zoom",
        date: Date.parse("June 8, 2021"),
        date_range: "February 8-11, 2021",
        hero: require("@/assets/hosts/virtual.png"),
        media: require("@/assets/hosts/virtual.png"),
        blurb: "14th Annual Flight Software Workshop",
        youtube_playlist:
            "https://youtube.com/playlist?list=PLK-T7jljJ6zb0ALxjewsZ3amBTHPXH7qC",
        tickets:
            "https://www.eventbrite.com/e/2021-flight-software-workshop-tickets-121058991703",
        subscribe: "https://lp.constantcontactpages.com/su/8w2sKSr/join",
        // slack: "",
        forms: true,
        free: true,
        sel_abstracts: true,
        // sel_abstracts_link: "",
        presentations: [],
        pres_slides_link:
            "https://drive.google.com/embeddedfolderview?id=1yS3PDSy1sBe4AAanuC2At4dGc24-gnS5",
        sponsors: [
            require("@/assets/sponsors/oar.png"),
            require("@/assets/sponsors/adacore.jpg"),
            require("@/assets/sponsors/concurrent-real-time.jpg"),
            require("@/assets/sponsors/cobham.jpg"),
            require("@/assets/sponsors/mathworks.png"),
            require("@/assets/sponsors/tttech.jpg")
        ],
        description: [
            "**The Johns Hopkins University Applied Physics Laboratory** in conjunction with **The NASA Jet Propulsion Laboratory**, **The Aerospace Corporation**, and **Southwest Research Institute**, is hosting the 13th Annual Workshop on Spacecraft Flight Software (FSW 2021) in Laurel, MD this summer.",
            "In the last several years there has been a significant increase in the next generation of flight system architectures for space. Some of the advancements have been in multicore, soft-core, FPGA's, space computers, operating systems, flight software and machine learning. This presents many opportunities in the space industry, as well as some significant challenges.",
            "**Our themes** for this year are flight software frameworks, code generation systems, software toolchains and processes, and onboard autonomy systems. The themes are meant _to help guide, but not limit_ potential presentation topics, which can be anything of interest to the flight software engineering community!",
            "This workshop provides an opportunity to present current flight architectures, novel approaches to mission solutions, and techniques for flight software development, integration, test and verification in an informal and open setting that facilitates communication across organizations and agencies. We are also inviting poster presentations from students.",
            "As a result of not needing a venue and not having other costs associated with an in-person workshop, the `2021 Flight Software Workshop will be FREE for attendees and presenters.`"
        ],
        live_schedule:
            "https://docs.google.com/spreadsheets/d/e/2PACX-1vRAaOO9CEoU7JXfA-SO27K5kFI-lL1TdsE3jLWhWe2UvD7yrzWPlf2V1_TcikW_v3kt2ERNBnbiZ-DY/pubhtml",
        schedule_download:
            "https://docs.google.com/spreadsheets/d/e/2PACX-1vRAaOO9CEoU7JXfA-SO27K5kFI-lL1TdsE3jLWhWe2UvD7yrzWPlf2V1_TcikW_v3kt2ERNBnbiZ-DY/pub?output=pdf",
        static_schedule: require("@/assets/static_docs/Static_2021_Workshop_Schedule.pdf"),
        schedule_info:
            "Below is the 2021 FSW Workshop Schedule. <br/><br/> \
            The daily Webinar Link can be found at the top right corner of each day, while Sponsor Kiosk links can be found on the right-side panel for Tue/Thr.<br/>\
            <b>The password for all Zoom Webinars and Meetings are sent out daily to attendees and presenters, please check your emails daily for the password.</b><br/> \
            If you can't find the daily e-mail from our team `check your spam folder`.<br/> For any questions feel free to reach out to us at: <a style='color:blue' href='mailto:info@flightsoftware.org'>info@flightsoftware.org</a><br/><br/>",
        attendee_info: {
            general: [
                {
                    p:
                        "The 2021 workshop will be held virtually to keep our staff and attendees safe during the COVID-19 pandemic. \
                This year, we have decided to make admissions to the 2021 FSW Workshop `FREE`, all that is necessary is an RSVP. \
                <b>If you haven't already done so, <a href='https://lp.constantcontactpages.com/su/8w2sKSr/join' style='color:blue'>subscribe to our e-mail list</a> to get the latest information from our organizing committee.</b>\
                <br/><br/> \
                We have surpassed 700 attendees for FSW-2021, continuing to set the record for the most attendees in FSW Workshop history! \
                Thanks to this influx, we have abstract submissions from over 20 unique organizations, covering a wide array of matters and \
                talented minds. \
                <br/><br/> \
                Our 2021 FSW Workshop Virtual event will be hosted via a combination of Zoom Webinar and Zoom Meetings. All presentations will be held\
                via Zoom Webinar, whereas kiosks will be held in Zoom Meetings. \
                **During presentations** only presenters will be able to share audio \
                and video. Attendees can use the Q&A to ask questions and interact with the presenters.\
                **During kiosks** sponsors will host the Zoom meeting room and attendees will have full interactivity. </br></br>"
                },
                {
                    table:
                        " </br>The table below shows the difference between the two and relates `FSW Workshop Roles` to **Zoom Roles**:\
                <br/><br/> \
                <table class='table is-hoverable is-bordered'> \
                    <thead> \
                    <tr> \
                        <th>Feature</th>\
                        <th>Webinar </br> (All Presentations)</th>\
                        <th>Meeting </br> (Sponsor Kiosks)</th>\
                    </tr>\
                    </thead>\
                    <tbody>\
                        <tr>\
                            <th>Participant Roles</th>\
                            <td> \
                                <ul class='list-item' style='list-style-type:square'> \
                                    <li>All `Presenters` will be **Panelists**. Only **Panelists** will be able to share video and audio during the Presentation</li>\
                                    <li>All `Attendees` will be Zoom **Attendees**. **Attendees** will ask questions via the Q&A section and may interact via Chat</li>\
                                    <li>All `FSW Workshop Reps` and `Technicians` will be **Hosts**. **Hosts** will act as moderators</li>\
                                </ul>\
                            </td>\
                            <td>\
                                <ul class='list-item' style='list-style-type:square'> \
                                    <li>**Hosts** will be `Sponsors` and FSW Workshop `Representatives`</li>\
                                    <li>**Participants** will be all `Attendees` in the kiosk</li>\
                                </ul>\
                            </td>\
                        </tr>\
                        <tr>\
                            <th>Audio/Video Control</th>\
                            <td>\
                                <ul class='list-item' style='list-style-type:square'> \
                                    <li>Only **Panelists** can mute/unmute their own audio</li>\
                                    <li>**Attendees** join in listen-only mode</li>\
                                    <li>During the Thursday Town Hall Session **Hosts** may unmute an attendee to partake in the live conversation</li>\
                                    <li>Video & Screen Sharing will only be available to **Panelists**</li>\
                                </ul>\
                            </td>\
                            <td>\
                                <ul class='list-item' style='list-style-type:square'> \
                                    <li>All **Participants** can mute/unmute their own audio</li>\
                                    <li>**Hosts** can mute/request to unmute **participants**</li>\
                                    <li>Video & Screen Sharing will be available to all **Participants**</li>\
                                </ul>\
                            </td>\
                        </tr>\
                        <tr>\
                            <th>Participant List</th>\
                            <td> \
                            <ul class='list-item' style='list-style-type:square'> \
                                <li>Visible to **Hosts** and **Panelists**</li>\
                            </td>\
                            <td> \
                            <ul class='list-item' style='list-style-type:square'> \
                                <li>Visible to all **Participants**</li> \
                            </td>\
                        </tr>\
                        <tr>\
                            <th>Networking</th>\
                            <td> \
                                <ul class='list-item' style='list-style-type:square'> \
                                    <li>Everyone  may use the **Webinar Chat**</li>\
                                    <li>**Attendees** may ask questions via the Q&A section</li> \
                                    <li>**Panelists** may answer Q&A section questions LIVE or via chat after their presentation</li>\
                                </ul> \
                            </td>\
                            <td>\
                                <ul class='list-item' style='list-style-type:square'> \
                                <li>Everyone may use the **In-meeting Chat**</li>\
                                </ul> \
                            </td>\
                        </tr>\
                    </tbody>\
                </table>"
                },
                {
                    p:
                        "</br><h1 class='subtitle'><b>General Instructions:</b></h1>\
                During the 2021 FSW Workshop, attendees and presenters should check their registered e-mails in the morning for a FSW Workshop e-mail with the password to the Zoom Webinar and Kiosks of the day. </br>\
                <ol class='list-item'> \
                    <li>Travel to the 2021 FSW Workshop Schedule (If you cannot see the LIVE schedule, you should be given the option to download a static version of it)</li> \
                    <li>Click on the tab that corresponds to the current Workshop Day</li> \
                    <li>For the Zoom Webinar of the day, click the link on the top right corner of schedule titled `<Day> Webinar Link`</li> \
                    <li>For the Kiosk Zoom Meeting of the day (available on Tuesday and Thursday), click on the links listed on the right panel of the schedule</li> \
                    <li>You will be prompted for a password to join the meeting. You can find the password in the e-mail sent out earlier that day by the FSW Workshop Committee</li> \
                </ol></br>\
                For general communication between all attendees, keynotes and presentors we will be using <a href='https://slack.com/help/articles/115004071768-What-is-Slack-' style='color:blue'>Slack</a>.\
                Slack is a channel-based platform that allows for all members to communicate in an organized fasion. We will be using channels to divide conversations into topics. The platform allows for \
                direct messaging amongst users, and for public discussions in public channels. We encourage all our attendees, presenters and keynotes to read the 2021 FSW Workshop Code of Conduct before\
                Access to the 2021 FSW Workshop Slack Workspace will be granted to all attendees via e-mail on February 7th, 2021.\
            "
                }
            ],
            // attendee: [{
            //     p: "Every morning attendees should check their registered e-mails for a FSW Workshop e-mail with the password to the Zoom Webinar and Kiosks of the day. </br> \
            //     <ol class='list-item'> \
            //         <li>Coffee</li> \
            //         <li>Tea</li> \
            //         <li>Milk</li> \
            //     </ol>"
            // }],
            // presenter: [{
            //     p: "Each presenter should join the Zoom at 10:30 AM ET on the day of their presentation.\
            //         This 1 hour ahead of the start of the Workshop, will be used to perform sound and video checks.    \
            //     <ol class='list-item'> \
            //         <li>Coffee</li> \
            //         <li>Tea</li> \
            //         <li>Milk</li> \
            //     </ol>"
            // }],
            code_of_conduct: [
                {
                    p:
                        "</br>\
                <h3 class='subtitle'>**Flight Software Workshop 2021 Code of Conduct**</h3>\
                In order to ensure we conduct a safe and fruitful workshop for all presenters and attendees **we would like all \
                our attendees and presenters to read and respect the Flight Software Workshop Code of Conduct below.** </br>\
                This code of conduct holds for both Zoom interactions & Interactions via Slack.</br></br>"
                },
                {
                    link: require("@/assets/static_docs/FSWW21_Code_of_Conduct.pdf")
                }
            ],
            // DEADLINE PASSED COMMENTING OUT
            // abstracts: [
            //     "**UPDATE:** <br />",
            //     "We have decided to extend the abstract submission deadline to January 8th to give more time to those of you accomodating final approval processes. <br />",
            //     "Submitters can expect a **notification of approval no later than January 15th, 2021**<br /> <br />",
            //     "--------------------------- <br /> <br />",
            //     "If you wish to present you **must submit an abstract by January 8th, 2021** <br /> <br />",
            //     '**E-mail your abstract to:** `abstracts@flightsoftware.org`',
            //     "**Subject line:** `Abstract Submission <Your Presentation Title>` <br /> <br />",
            //     "This is a presentation-only workshop (no papers). Those wishing to present must submit an abstract by the deadline listed above. \
            //     Keep in mind that: <br /> <br />",

            //     "   <li>All abstracts and presentations must be ready for public release. <br /> <br /></li> \
            //         <li>Presentations are video-taped and published on this website at a later date. Therefore, \
            //             presentations MUST NOT contain US Export Controlled or ITAR information, and notice of this must be indicated on all presentations. <br /> <br /></li> \
            //         <li>All primary authors of the selected presentations must submit required release forms, which will be provided when notified of their acceptance to the Workshop. \
            //             We recommend that selected authors review the release form with the legal experts in their organization before signing and uploading their presentations prior to the deadline. <br /> <br /></li> \
            //     ",

            //     "Primary authors will receive a Google Drive URL (to upload Abstracts \
            //     and Presentations) after registration. <br />",
            //     "Since abstract submissions are coming in, we wanted to remind attendees that the agenda is already being set. As the Workshop is presentation-only, the abstract submissions are an overview to the presentation for Workshop Organizing Committee selection and attendee information, and do not have to be a formal paper. <br /> <br />",
            //     "We’ve received great submissions thus far and are looking forward to filling out the rest of the agenda for the abstract submission deadline of January 1st, 2021.",
            // ],
            faqs: [
                {
                    Q: "When will Talk recordings become available online?",
                    A:
                        "Live Talk recordings this year will not become available immidiately after the presentation. Using Zoom Webinar does not allow us to directly publish to YouTube. However \
                    we aim to publish all Live Presentation recordings by May 1st, 2021. This provides this year's FSW Workshop Committee with sufficient time to edit the recordings \
                    and post each talk as an individual video to our YouTube channel."
                },
                {
                    Q: "What virtual platform will the FSW Workshop be using?",
                    A:
                        "The Workshop Committee has chosen Zoom Webinar for this year's virtual Workshop. The Webinar platform manages presenters and attendees \
                    such that only presenters may be unmuted, and includes a Q&A system with upvotes. Webinar is accessible via web browser, as well as Windows, \
                    Linux, and Mac clients."
                },
                {
                    Q: "How long are presentations?",
                    A:
                        "Presentations are 25 minutes long. Presenters may choose to include \
                    Q&A within this given time."
                },
                {
                    Q:
                        "What options for interactivity, like Q&A, will be supported?",
                    A:
                        "As mentioned, there is the Zoom Webinar Q&A system for presenters to interact with attendees. Questions can be submitted by any \
                    attendee via a text platform and presenters may respond live or by written response. Questions can be upvoted by all attendees to reflect \
                    audience interest.\
                    We are also planning to provide an optional Slack Workspace for further interactivity and networking opportunities. More details will be \
                    provided prior to the conference."
                },
                {
                    Q: "What time does the Workshop start/end?",
                    A:
                        "The Workshop will run from approximately 11:30 AM to approximately 6:20 PM Eastern Time, Monday through Thursday. This is to enable \
                    attendees from all U.S. time zones. For any of those unable to attend due to conflict or time zone, all talks will be published to the \
                    FSW Workshop YouTube after the conference. We appreciate our international attendees and presenters for supporting the Workshop as they \
                    are able!"
                },
                {
                    Q: "When will the agenda be released?",
                    A:
                        "The Workshop Committee will tentatively release the agenda on Monday, \
                    February 1st, pending confirmation from all presenters regarding their \
                    time slots."
                },
                {
                    Q: "How do I access the Webinar?",
                    A:
                        "The Webinar link will be available on the FSW Workshop website, \
                    as well as emailed to all attendees. The Webinar will be secured via password, \
                    which will also be included in the email to all attendees. The link will open up \
                    your desktop Zoom application if available, and a browser if not."
                },
                {
                    Q:
                        "How do I interact with the Workshop sponsors in a virtual conference?",
                    A:
                        "Sponsor Kiosks - Each sponsor is hosting an individual sponsor kiosk through Zoom, \
                    on Tuesday and Thursday during a dedicated 45 minutes of the Workshop agenda. Each sponsor \
                    will also have the opportunity to share a summary of their kiosk with the Workshop beforehand.\
                    Sponsor Presentations - Each sponsor is giving a 25-minute presentation during the Workshop \
                    to share their work with the community."
                }
            ]
        },
        timeline: [
            {
                name: "Registration Opens",
                date: "October 1st, 2020",
                note: "",
                done: true
            },
            {
                name: "First Call for Abstracts",
                date: "October 1st, 2020",
                note: "",
                done: true
            },
            {
                name: "Abstract Submission Deadline",
                date: "January 1st, 2021",
                note: "",
                done: true
            },
            {
                name: "Abstract Acceptance Notification",
                date: "January 15th, 2021",
                note: "",
                done: true
            },
            {
                name: "Presentation Submission Deadline",
                date: "February 1st, 2021",
                note: "",
                done: true
            },
            {
                name: "Workshop",
                date: "February 8-11, 2021",
                note: "",
                done: true
            },
            {
                name: "Publish Video Talks",
                date: "May, 2021",
                note: "",
                done: false
            }
        ],
        committee: [
            {
                position: "General Chair",
                name: "Chris Monaco",
                institution: "The JHU Applied Physics Laboratory",
                image: ""
            },
            {
                position: "Event Coordinator Chair",
                name: "Luis M. Rodriguez",
                institution: "The JHU Applied Physics Laboratory",
                image: ""
            },
            {
                position: "Technical Chair",
                name: "Brandon T. Haber",
                institution: "The JHU Applied Physics Laboratory",
                image: ""
            },
            {
                position: "Networking Chair",
                name: "David McComas",
                institution: "NASA Emeritus",
                image: ""
            },
            {
                position: "Finance Chair",
                name: "Joseph E. Daly",
                institution: "The JHU Applied Physics Laboratory",
                image: ""
            },
            {
                position: "Communications Chair",
                name: "Campbell A. Ong",
                institution: "The JHU Applied Physics Laboratory",
                image: ""
            },
            {
                position: "Virtual Hosting Chair",
                name: "David J. Edell",
                institution: "The JHU Applied Physics Laboratory",
                image: ""
            },
            {
                position: "Website Maintainer",
                name: "Luis M. Rodriguez",
                institution: "The JHU Applied Physics Laboratory",
                image: ""
            }
        ],
        keynotes: [
            {
                position: "Chief Engineer of the Space Exploration Sector",
                name: "Steve Thibault",
                institution: "The JHU Applied Physics Laboratory",
                talk_title:
                    "A system’s vision for software technology advancement in robotic spaceflight",
                image: require("@/assets/keynotes/2021/thibasp1_LThumb.jpg"),
                bio:
                    "Mr. Steve Thibault currently serves as the Chief Engineer in the Space Exploration Sector at Johns " +
                    "Hopkins University Applied Physics Laboratory (JHUAPL).  Mr. Thibault has over 30 years of experience in " +
                    "space systems development, production, integration and test, launch, field campaigns, and mission operations.  " +
                    "He is currently the independent engineering authority for all spacecraft and instrument development and operations " +
                    "underway at JHUAPL. \n\n " +
                    "\n" +
                    "Mr. Thibault joined JHUAPL in 2006 as a line manager for spacecraft integration, test, and mission operations.  " +
                    "He oversaw the technical implementation of the STEREO environmental testing and launch campaign, the Radiation " +
                    "Belt Storm Probe integration and test, operations of the Satellite Communications Facility, and spaceflight " +
                    "operation of TIMED, MESSENGER, New Horizons, STEREO, and Van Allen Probes.  He also led the test completion " +
                    "of the Mini-RF instruments for NASA with deliveries to the Lunar Reconnaissance Orbiter and the ISRO Chandrayaan-1 " +
                    "lunar spacecraft.  In 2013, he served as the Chief Engineer for the NOAA Satellite and Information Service (NESDIS) " +
                    "reporting directly to the Assistant Administrator as an Interagency Personnel Assignment and created the Office of Systems " +
                    "Architecture and Advanced Planning.  Upon his return to JHUAPL, Mr. Thibault served as the space power, parts, and radiation " +
                    "manager with specific emphasis on the Parker Solar Probe development, eventually becoming the Deputy Project Manager for " +
                    "Implementation and then the Spacecraft Systems Engineer to complete critical development with the spacecraft at the launch " +
                    "site and led the engineering team through in-orbit commissioning and the first Venus flyby.  In 2019, Mr. Thibault was selected " +
                    "as the JHU APL Space Exploration Sector Chief Engineer. \n " +
                    "\n" +
                    "At Orbital Sciences prior to 2006, Mr. Thibault led the satellite manufacturing with specific contributions to creating its " +
                    "geostationary communications product line from inception, oversight of small science satellite deliveries including GEMstar, " +
                    "and establishing its first planetary integration and test capability with DAWN.  In his decade at Orbital, he oversaw the " +
                    "delivery of nine GEOcomm satellites, led launch campaigns in French Guyana and Kazakhstan, and led field deployment in Jakarta, " +
                    "Indonesia.  He started his career at Analytic Services (ANSER) in 1987 performing technology analysis for the Strategic Defense " +
                    "Initiative Organization’s Advanced Launch System and interceptor suborbital field tests for the Lightweight Exo-Atmospheric Projectile.\n " +
                    "\n" +
                    "Mr. Thibault has a Bachelor’s degree in Aeronautical Engineering from Rensselaer Polytechnic Institute, a Master’s degree in Applied " +
                    "hysics from Johns Hopkins University, and is currently in the dissertation phase for a Doctoral degree in Materials Science and " +
                    "Engineering from Johns Hopkins University."
            },
            {
                position: "Vice President Space Program Operations",
                name: "Kevin D Bell",
                institution: "Aerospace Corporation",
                talk_title:
                    "An Agile Space Enterprise to Keep Pace with Technology and the Threat",
                image: require("@/assets/keynotes/2021/Bell-webbio-pic2.jpg"),
                bio:
                    "Kevin D. Bell is is vice president, Space Program Operations, Space Systems " +
                    "Group at The Aerospace Corporation. Bell was appointed to this position on " +
                    "April 1, 2017. In this role, Bell works directly with the Air Force, government, and " +
                    "industry partners to develop military satellites and to advance national security " +
                    "space systems. He assists with the development of system requirements, " +
                    "provides schedule/cost risk assessments, and solves systems development " +
                    "problems. Bell oversees four major mission areas: communications, " +
                    "surveillance, weather, and navigation. " +
                    "\n \n " +
                    "Bell joined Aerospace in 1992 as a member of the technical staff in Vehicle " +
                    "Systems Division of the Engineering and Technology Group (ETG). Most " +
                    "recently, Bell was general manager of the Imagery Programs Division, National " +
                    "Systems Group, and prior to that he was general manager of the Systems " +
                    "Engineering Division in ETG. " +
                    "\n \n " +
                    "Bell has also supported the Air Force, NASA, commercial ventures, Missile " +
                    "Defense Agency, and the National Reconnaissance Organization customers, " +
                    "serving as systems director of the Space and Directed Energy Technology " +
                    "Directorate; principal director of the Missile Defense Division, Systems and " +
                    "Technology Subdivision; and principal director of Advanced Research and " +
                    "Engineering, Advanced Technology Division, among other increasingly " +
                    "responsible positions within the corporation’s National Systems Group. Prior " +
                    "to joining Aerospace, Bell held positions in industry, academia, and at the " +
                    "NASA Ames Research Center. " +
                    "\n \n " +
                    "The Aerospace Corporation is an independent, nonprofit organization dedicated " +
                    "to the objective application of science and technology toward the solution of " +
                    "critical issues affecting the nation’s space program."
            },
            {
                position: "Software Engineer and Artist",
                name: "Don Eyles",
                institution:
                    "Author of Sunburst and Luminary: An Apollo Memoir",
                talk_title: "Flight Software Comes from Mars",
                image: require("@/assets/keynotes/2021/Don-Eyles-300x300.jpg"),
                bio:
                    "Don Eyles built flight software for 32 years at the MIT Instrumentation Lab and the Charles Stark Draper Laboratory in Cambridge. " +
                    "During the Apollo Project he wrote onboard software for the guidance computer on the Lunar Module, including the code that guided the " +
                    "spacecraft during the descent from orbit to the lunar surface. He originated a sequencing system called Timeliner (also called UIL) that " +
                    "is currently in operation on the International Space Station. He retired in 1998 and is active as an artist in Boston. His memoir of the " +
                    "Apollo Project, titled 'Sunburst and Luminary,' was published in 2018."
            },
            {
                position: "Mission Area Executive for Civil Space",
                name: "Jason Kalirai",
                institution: "The JHU Applied Physics Laboratory",
                talk_title: "Space Exploration at Johns Hopkins APL",
                image: require("@/assets/keynotes/2021/JasonKaliraiJHUAPLsmaller.jpg"),
                bio:
                    "Dr. Jason Kalirai is the Mission Area Executive " +
                    "for Civil Space in the Space Exploration Sector. He joined " +
                    "APL in November 2018 and is leading the implementation of " +
                    "innovative and cost-effective solutions to critical civil " +
                    "space challenges by developing space science missions, instruments, " +
                    "and research programs.  Prior to joining APL, Dr. Kalirai served " +
                    "as the multi-mission project scientist at NASA’s Space Telescope " +
                    "Science Institute.  He has published over 100 research papers on " +
                    "topics related to stellar and galactic evolution and has won numerous " +
                    "awards for his achievements.  Dr. Kalirai earned his Bachelor of Science " +
                    "in physics and astronomy, his Master of Science in astrophysics, and his " +
                    "PhD in astrophysics from the University of British Columbia.  " +
                    "He completed a postdoctoral fellowship as a Hubble Fellow at the " +
                    "University of California at Santa Cruz."
            }
        ]
    },
    FSW2019: {
        name: "2019 FSW Workshop",
        id: "FSW2019",
        public: true,
        open: false,
        old_site: true,
        old_site_link:
            "http://flightsoftware.jhuapl.edu/files/_site/workshops/old_archive/2019/",
        host: " at NASA Marshall Space Flight Center",
        location: "Huntsville, AL",
        date: Date.parse("December 9, 2019"),
        date_range: "December 9-12, 2019",
        hero: require("@/assets/hosts/marshall.jpg"),
        media: require("@/assets/hosts/marshall.jpg"),
        blurb: "13th Annual Flight Software Workshop",
        youtube_playlist:
            "https://www.youtube.com/watch?v=46JtinmZooY&list=PLK-T7jljJ6zakr4HLuHrti7uYYSU4xUIm",
        description: [
            "NASA Marshall Space Flight Center, in conjunction with the NASA Jet Propulsion Laboratory, The Aerospace Corporation, Southwest Research Institute, and The Johns Hopkins University Applied Physics Laboratory, is hosting the 12th Annual Workshop on Spacecraft Flight Software (FSW 2019) in Huntsville, Alabama from December 9th to 12th 2019.",
            "In the last several years there has been a significant increase in the next generation of flight system architectures for space. Some of the advancements have been in multicore, soft-core, FPGA's, space computers, operating systems, flight software and machine learning. This presents many opportunities in the space industry, as well as some significant challenges. As such, this year the theme of the Flight Software Workshop is the Next Generation of Flight System Architectures. Presentations focused on the impact of the Next Generation of Flight System Architecture are encouraged.",
            "This workshop provides an opportunity to present current flight architectures, novel approaches to mission solutions, and techniques for flight software development, integration, test and verification in an informal and open setting that facilitates communication across organizations and agencies. We are also inviting poster presentations from students. In addition to the overall theme of Commercial Space, a list of potential topic areas is provided further down.",
            "Please note that beginning this year, the NASA cFS Community Day presentations, registrations, further communications and other logistics will be merged with Flight Software Workshop. This will help streamline our processes and improve logistics management for workshop volunteers.",
            "UPDATE: We are excited to announce that after the close of the Flight Software Workshop on Thursday, a special session will be conducted to address Cybersecurity Concerns for Software Developers. The presentations will focus on current cybersecurity infrastructure, strategies for software architects, cybersecurity challenges and issues. The information will apply to both Flight Software and Ground Software Developers. Due to the potentially sensitive nature of the presentations, the Cybersecurity Session will only be open to U.S. Citizens. Further details about attendance for this session will be forthcoming."
        ],
        sponsors: [
            require("@/assets/sponsors/oar.png"),
            require("@/assets/sponsors/adacore.jpg"),
            require("@/assets/sponsors/mathworks.png"),
            require("@/assets/sponsors/tttech.jpg"),
            require("@/assets/sponsors/concurrent-real-time.jpg"),
            require("@/assets/sponsors/cobham.jpg")
        ],
    },
    FSW2018: {
        name: "2018 FSW Workshop",
        id: "FSW2018",
        public: true,
        open: false,
        old_site: true,
        old_site_link:
            "http://flightsoftware.jhuapl.edu/files/_site/workshops/old_archive/2018/",
        date: Date.parse("December 3, 2018"),
        date_range: "December 3-6, 2018",
        host: " at Southwest Research Institute",
        location: "San Antonio, TX",
        hero: require("@/assets/hosts/swri.jpg"),
        media: require("@/assets/hosts/swri.jpg"),
        blurb: "12th Annual Flight Software Workshop",
        description: [
            "Southwest Research Institute in conjunction with the NASA Jet Propulsion Laboratory, The Aerospace Corporation, and The Johns Hopkins University Applied Physics Laboratory, hosted the 11th Annual Workshop on Spacecraft Flight Software (FSW-2018) in San Antonio, Texas from December 3rd to 6th 2018."
        ],
        sponsors: [
            require("@/assets/sponsors/oar.png"),
            require("@/assets/sponsors/adacore.jpg"),
            require("@/assets/sponsors/mathworks.png")
        ]
    },
    FSW2017: {
        name: "2017 FSW Workshop",
        id: "FSW2017",
        public: true,
        open: false,
        old_site: true,
        old_site_link:
            "http://flightsoftware.jhuapl.edu/files/_site/workshops/old_archive/2017/",
        date: Date.parse("November 13, 2017"),
        date_range: "December 4-6, 2017",
        host: " at The Johns Hopkins Applied Physics Laboratory",
        location: "Laurel, MD",
        hero: require("@/assets/hosts/apl.jpeg"),
        media: require("@/assets/hosts/apl.jpeg"),
        blurb: "11th Annual Flight Software Workshop",
        description: [
            "The 10 Year anniversary 2017 Workshop on Spacecraft Flight Software (FSW-17) was held on December 4-7, 2017 at the Building 200 E-100 Auditorium, The Johns Hopkins University Applied Physics Laboratory, 11101 Johns Hopkins Road, Laurel, MD 20723."
        ],
        sponsors: [
            require("@/assets/sponsors/oar.png"),
            require("@/assets/sponsors/adacore.jpg"),
            require("@/assets/sponsors/mathworks.png"),
            require("@/assets/sponsors/tttech.jpg"),
            require("@/assets/sponsors/concurrent-real-time.jpg"),
            require("@/assets/sponsors/cobham.jpg"),
            require("@/assets/sponsors/agi.png")
        ]
    },
    FSW2016: {
        name: "2016 FSW Workshop",
        id: "FSW2016",
        public: true,
        open: false,
        old_site: true,
        old_site_link:
            "http://flightsoftware.jhuapl.edu/files/_site/workshops/old_archive/2016/",
        host: " at Beckman Institute at the California Institute of Technology",
        location: "Pasadena, CA",
        date: Date.parse("December 9, 2016"),
        date_range: "December 13-15, 2016",
        hero: require("@/assets/hosts/caltech.jpg"),
        media: require("@/assets/hosts/caltech.jpg"),
        blurb: "10th Annual Flight Software Workshop",
        description: [
            "The 2016 Workshop on Spacecraft Flight Software (FSW-16) was held at The Beckman Institute Auditorium, California Institute of Technology by NASA Jet Propulsion Laboratory, in conjunction with The Aerospace Corporation and The Johns Hopkins University Applied Physics Laboratory."
        ],
        sponsors: [
            require("@/assets/sponsors/oar.png"),
            require("@/assets/sponsors/adacore.jpg"),
            require("@/assets/sponsors/mathworks.png"),
            require("@/assets/sponsors/tttech.jpg"),
            require("@/assets/sponsors/star-dundee.png")
        ]
    },
    FSW2015: {
        name: "2015 FSW Workshop",
        id: "FSW2015",
        public: true,
        open: false,
        old_site: true,
        old_site_link:
            "http://flightsoftware.jhuapl.edu/files/_site/workshops/old_archive/2015/",
        date: Date.parse("November 13, 2015"),
        date_range: "October 27-29, 2015",
        host: " at The Johns Hopkins Applied Physics Laboratory",
        location: "Laurel, MD",
        hero: require("@/assets/hosts/apl.jpeg"),
        media: require("@/assets/hosts/apl.jpeg"),
        blurb: "9th Annual Flight Software Workshop",
        description: [
            "The 2015 Workshop on Spacecraft Flight Software (FSW-15) was held on October 27-29, 2015 at the Building 200 E-100 Auditorium, The Johns Hopkins University Applied Physics Laboratory, 11101 Johns Hopkins Road, Laurel, MD 20723."
        ],
        sponsors: [
            require("@/assets/sponsors/oar.png"),
            require("@/assets/sponsors/adacore.jpg"),
            require("@/assets/sponsors/mathworks.png"),
            require("@/assets/sponsors/tttech.jpg"),
            require("@/assets/sponsors/cobham.jpg"),
            require("@/assets/sponsors/agi.png")
        ]
    },
    FSW2014: {
        name: "2014 FSW Workshop",
        id: "FSW2014",
        public: true,
        open: false,
        old_site: true,
        old_site_link:
            "http://flightsoftware.jhuapl.edu/files/_site/workshops/old_archive/2014/",
        host: " at Beckman Institute at the California Institute of Technology",
        location: "Pasadena, CA",
        date: Date.parse("December 9, 2014"),
        date_range: "December 16-18, 2014",
        hero: require("@/assets/hosts/caltech.jpg"),
        media: require("@/assets/hosts/caltech.jpg"),
        blurb: "8th Annual Flight Software Workshop",
        description: [
            "The 2014 Workshop on Spacecraft Flight Software (FSW-14) was held on December 16-18, 2014 at the Beckman Institute at the California Insitute of Technology, with support from the Jet Propulsion Laboratory, the Aerospace Corporation, and the Johns Hopkins University Applied Physics Laboratory. Please see the workshop agenda and presentations below."
        ],
        sponsors: [
            require("@/assets/sponsors/oar.png"),
            require("@/assets/sponsors/adacore.jpg"),
            require("@/assets/sponsors/mathworks.png"),
            require("@/assets/sponsors/tttech.jpg"),
            require("@/assets/sponsors/klockwork.jpg"),
            require("@/assets/sponsors/grammatech.jpg")
        ]
    },
    FSW2013: {
        name: "2013 FSW Workshop",
        id: "FSW2013",
        public: true,
        open: false,
        old_site: true,
        old_site_link:
            "http://flightsoftware.jhuapl.edu/files/_site/workshops/old_archive/2013/",
        host: " at Beckman Institute at the California Institute of Technology",
        location: "Pasadena, CA",
        date: Date.parse("December 9, 2013"),
        date_range: "December 10-12, 2013",
        hero: require("@/assets/hosts/caltech.jpg"),
        media: require("@/assets/hosts/caltech.jpg"),
        blurb: "7th Annual Flight Software Workshop",
        description: [
            "The 2013 Workshop on Spacecraft Flight Software (FSW-13) was held on December 10-12, 2013 at the Beckman Institute at the California Insitute of Technology, with support from the Jet Propulsion Laboratory, the Aerospace Corporation, and the Johns Hopkins University Applied Physics Laboratory."
        ],
        sponsors: [
            require("@/assets/sponsors/oar.png"),
            require("@/assets/sponsors/mathworks.png"),
            require("@/assets/sponsors/tttech.jpg"),
            require("@/assets/sponsors/klockwork.jpg"),
            require("@/assets/sponsors/grammatech.jpg"),
            require("@/assets/sponsors/beagleboard.jpg")
        ]
    },
    FSW2012: {
        name: "2012 FSW Workshop",
        id: "FSW2012",
        public: true,
        open: false,
        old_site: true,
        old_site_link:
            "http://flightsoftware.jhuapl.edu/files/_site/workshops/old_archive/2012/",
        date: Date.parse("November 13, 2012"),
        date_range: "November 7-9, 2012",
        host: " at Southwest Research Institute",
        location: "San Antonio, TX",
        hero: require("@/assets/hosts/swri.jpg"),
        media: require("@/assets/hosts/swri.jpg"),
        blurb: "6th Annual Flight Software Workshop",
        description: [
            "The 2012 Workshop on Spacecraft Flight Software (FSW-12) was held on November 7-9, 2012 and was hosted by the Southwest Research Institute with support from the Jet Propulsion Laboratory, the Aerospace Corporation, and the Johns Hopkins University Applied Physics Laboratory."
        ],
        sponsors: [
            require("@/assets/sponsors/oar.png"),
            require("@/assets/sponsors/grammatech.jpg"),
            require("@/assets/sponsors/ldra.png"),
            require("@/assets/sponsors/aeroflex.jpg")
        ]
    },
    FSW2011: {
        name: "2011 FSW Workshop",
        id: "FSW2011",
        public: true,
        open: false,
        old_site: true,
        old_site_link:
            "http://flightsoftware.jhuapl.edu/files/_site/workshops/old_archive/2011/",
        date: Date.parse("November 13, 2011"),
        date_range: "October 19-21, 2011",
        host: " at The Johns Hopkins Applied Physics Laboratory",
        location: "Laurel, MD",
        hero: require("@/assets/hosts/apl.jpeg"),
        media: require("@/assets/hosts/apl.jpeg"),
        blurb: "5th Annual Flight Software Workshop",
        description: [
            "The 2010 Workshop on Spacecraft Flight Software (FSW-10) was held on December 8th-10th 2010 and was hosted by the Aerospace Corporation with support from the Jet Propulsion Laboratory and the Johns Hopkins University Applied Physics Laboratory. The workshop was held at the Beckman Institute at the California Institute of Technology, Pasadena, CA."
        ],
        sponsors: [
            require("@/assets/sponsors/oar.png"),
            require("@/assets/sponsors/grammatech.jpg"),
            require("@/assets/sponsors/agi.png"),
            require("@/assets/sponsors/asi.jpg")
        ]
    },
    FSW2010: {
        name: "2010 FSW Workshop",
        id: "FSW2010",
        public: true,
        open: false,
        old_site: true,
        old_site_link:
            "http://flightsoftware.jhuapl.edu/files/_site/workshops/old_archive/2010/",
        host: " at Beckman Institute at the California Institute of Technology",
        location: "Pasadena, CA",
        date: Date.parse("December 9, 2010"),
        date_range: "December 8-10, 2010",
        hero: require("@/assets/hosts/caltech.jpg"),
        media: require("@/assets/hosts/caltech.jpg"),
        blurb: "4th Annual Flight Software Workshop",
        description: [
            "The 2010 Workshop on Spacecraft Flight Software (FSW-10) was held on December 8th-10th 2010 and was hosted by the Aerospace Corporation with support from the Jet Propulsion Laboratory and the Johns Hopkins University Applied Physics Laboratory. The workshop was held at the Beckman Institute at the California Institute of Technology, Pasadena, CA."
        ],
        sponsors: [
            require("@/assets/sponsors/aerospace.jpg"),
            require("@/assets/sponsors/tttech.jpg"),
            require("@/assets/sponsors/asi.jpg"),
            require("@/assets/sponsors/grammatech.jpg"),
            require("@/assets/sponsors/coverity.jpg")
        ]
    },
    FSW2009: {
        name: "2009 FSW Workshop",
        id: "FSW2009",
        public: true,
        open: false,
        old_site: true,
        old_site_link:
            "http://flightsoftware.jhuapl.edu/files/_site/workshops/old_archive/2009/",
        host: " at Beckman Institute at the California Institute of Technology",
        location: "Pasadena, CA",
        date: Date.parse("December 9, 2009"),
        date_range: "November 4-6, 2009",
        hero: require("@/assets/hosts/caltech.jpg"),
        media: require("@/assets/hosts/caltech.jpg"),
        blurb: "3rd Annual Flight Software Workshop",
        description: [
            "The 2009 Workshop on Spacecraft Flight Software (FSW-09) was held on November 4-6, 2009 and hosted by the Jet Propulsion Laboratory at the California Institute of Technology, Cahill Center for Astronomy and Astrophysics, Pasadena, CA."
        ],
        sponsors: [
            require("@/assets/sponsors/oar.png"),
            require("@/assets/sponsors/adacore.jpg"),
            require("@/assets/sponsors/mathworks.png"),
            require("@/assets/sponsors/tttech.jpg"),
            require("@/assets/sponsors/concurrent-real-time.jpg"),
            require("@/assets/sponsors/cobham.jpg"),
            require("@/assets/sponsors/agi.png")
        ]
    },
    FSW2008: {
        name: "2008 FSW Workshop",
        id: "FSW2008",
        public: true,
        open: false,
        old_site: true,
        old_site_link:
            "http://flightsoftware.jhuapl.edu/files/_site/workshops/old_archive/2008/",
        date: Date.parse("November 13, 2008"),
        date_range: "November 13-14, 2008",
        host: " at The Johns Hopkins Applied Physics Laboratory",
        location: "Laurel, MD",
        hero: require("@/assets/hosts/apl.jpeg"),
        media: require("@/assets/hosts/apl.jpeg"),
        blurb: "2nd Annual Flight Software Workshop",
        description: [
            "The 2008 Workshop on Spacecraft Flight Software was held at The Johns Hopkins University Applied Physics Laboratory on November 13-14, 2008. "
        ],
        sponsors: [
            require("@/assets/sponsors/oar.png"),
            require("@/assets/sponsors/adacore.jpg"),
            require("@/assets/sponsors/mathworks.png"),
            require("@/assets/sponsors/tttech.jpg"),
            require("@/assets/sponsors/concurrent-real-time.jpg"),
            require("@/assets/sponsors/cobham.jpg"),
            require("@/assets/sponsors/agi.png")
        ]
    },
    FSW2007: {
        name: "2007 FSW Workshop",
        id: "FSW2007",
        public: true,
        open: false,
        old_site: true,
        old_site_link:
            "http://flightsoftware.jhuapl.edu/files/_site/workshops/old_archive/2007/",
        date: Date.parse("November 5, 2007"),
        date_range: "November 5 - 6, 2007",
        host: " at The Johns Hopkins Applied Physics Laboratory",
        location: "Laurel, MD",
        hero: require("@/assets/hosts/apl.jpeg"),
        media: require("@/assets/hosts/apl.jpeg"),
        blurb: "1st Flight Software Workshop",
        description: [
            "The 2007 Workshop on Spacecraft Flight Software was held at The Johns Hopkins University Applied Physics Laboratory on November 5-6, 2007."
        ],
        sponsors: [
            require("@/assets/sponsors/oar.png"),
            require("@/assets/sponsors/adacore.jpg"),
            require("@/assets/sponsors/mathworks.png"),
            require("@/assets/sponsors/tttech.jpg"),
            require("@/assets/sponsors/concurrent-real-time.jpg"),
            require("@/assets/sponsors/cobham.jpg"),
            require("@/assets/sponsors/agi.png")
        ]
    }
};

export default workshops;
